import * as storageKeys from "../../constants/storageKeys";
import * as settings from "../../constants/settings";
import moment from "moment";
import { decodeJWT } from "../utility";

export const removeSessionItems = () => {
  localStorage.removeItem(storageKeys.TOKEN);
};

export const setSessionItems = data => {
  localStorage.setItem(storageKeys.TOKEN, data.token);
};

export const getToken = () => {
  return localStorage.getItem(storageKeys.TOKEN);
};

export const getExpirationDate = () => {
  const payload = decodeJWT(localStorage.getItem(storageKeys.TOKEN));
  return new Date(payload.exp * 1000)
};


export const getDateRangeSelection = () => {
  // return JSON.parse(localStorage.getItem(storageKeys.DATE_RANGE_SELECTION));
  const _settings = JSON.parse(
    localStorage.getItem(storageKeys.DATE_RANGE_SELECTION)
  ) || {
    id: settings.DATE_RANGE_SELECTION_LATEST,
    numDays: 180,
    startDate: moment()
      .subtract(180, 'days')
      .format("YYYY-MM-DD"),
    endDate: moment()
      .format("YYYY-MM-DD")
  };
  return _settings;
};

export const setDateRangeSelection = data => {
  localStorage.setItem(storageKeys.DATE_RANGE_SELECTION, JSON.stringify(
    data));
};

export const getLatestObservationDate = () => {
  return localStorage.getItem(storageKeys.LATEST_OBSERVATION_DATE);
};

export const setLatestObservationDate = data => {
  localStorage.setItem(storageKeys.LATEST_OBSERVATION_DATE, data);
};

export const removeLatestObservationDate = () => {
  localStorage.removeItem(storageKeys.LATEST_OBSERVATION_DATE);
};

export const setDataRefreshDate = () => {
  localStorage.setItem(storageKeys.DATA_REFRESH_DATE, new Date());
};

export const getSettings = () => {
  const _settings = JSON.parse(
    localStorage.getItem(storageKeys.TILE_DOWNLOAD_SETTINGS)
  ) || {
    // selectedTDB: settings.TILE_DOWNLOAD_BOUNDARY_MAP,
    // selectedProjects: [],
    // maxZoomLevel: 16,
    imageryTypes: [
      settings.IMAGERY_TYPE_BASEMAP,
      settings.IMAGERY_TYPE_NATURAL_COLOR,
      settings.IMAGERY_TYPE_SINGLE_BAND,
      settings.IMAGERY_TYPE_REFERENCE
    ],
    addlDataTypes: [settings.ADDLDATA_TYPE_COMMENT],
    concurrentRequests: 8
  };
  return _settings;
};

export const setSettings = data => {
  localStorage.setItem(
    storageKeys.TILE_DOWNLOAD_SETTINGS,
    JSON.stringify(data)
  );
};

export const getGPSSettings = () => {
  const _settings = JSON.parse(
    localStorage.getItem(storageKeys.GPS_SETTINGS)
  ) || {
    gps:{
      watch: false,
      highAccuracy: false
    }
  };
  return _settings;
};

export const setGPSSettings = data => {
  localStorage.setItem(
    storageKeys.GPS_SETTINGS,
    JSON.stringify(data)
  );
};

export const setMapZoomCenter = (data) => {
  localStorage.setItem(storageKeys.MAP_ZOOM_CENTER, JSON.stringify(data))
}

export const getMapZoomCenter = () => {
  const _setting = JSON.parse(localStorage.getItem(storageKeys
    .MAP_ZOOM_CENTER)) || {
    zoom: 1,
    center: [0, 0]
  };
  return _setting
};

export const setDarkMode = (data) => {
  localStorage.setItem(storageKeys.DARK_MODE, data)
}

export const getDarkMode = () => {
  return (localStorage.getItem(storageKeys.DARK_MODE) ? (localStorage.getItem(storageKeys.DARK_MODE) === 'false' ? false : true) : false);
};

export const setOfflineMode = (data) => {
  localStorage.setItem(storageKeys.OFFLINE_MODE, data)
}

export const getOfflineMode = () => {
  return (localStorage.getItem(storageKeys.OFFLINE_MODE) ? (localStorage.getItem(storageKeys.OFFLINE_MODE) === 'true' ? true : false) : false);
};

export const setAppInitialized = (data) => {
  localStorage.setItem(storageKeys.APP_INITIALIZED, data)
}

export const getAppInitialized = () => {
  return (localStorage.getItem(storageKeys.APP_INITIALIZED) ? (localStorage.getItem(storageKeys.APP_INITIALIZED) === 'false' ? false : true) : false);
};

export const getBucket = () => {
  return localStorage.getItem(storageKeys.BUCKET);
};

export const setBucket = (data) => {
  localStorage.setItem(storageKeys.BUCKET, data)
}

export const getThresholdSettings = () => {
  const thresholdSettings = localStorage.getItem(storageKeys.DOWNLOAD_THRESHOLD_SETTINGS)

  if (!thresholdSettings) { 
    setThresholdSettings(settings.CACHE_THRESHOLD_DEFAULTS);
    return settings.CACHE_THRESHOLD_DEFAULTS
  }
  return JSON.parse(thresholdSettings);

}

export const setThresholdSettings = (newSettings) => {
  localStorage.setItem(storageKeys.DOWNLOAD_THRESHOLD_SETTINGS, JSON.stringify(newSettings))
}

export const getShowExampleProjectsSetting = () => {
  const showExampleProjectsSetting = localStorage.getItem(storageKeys.SHOW_EXAMPLE_PROJECTS)

  if (showExampleProjectsSetting === null) {
    console.log('%c Giving default value for example projects', 'color: #BADA55');
    setShowExampleProjectsSetting(true)
    return true;
  }
  
  return showExampleProjectsSetting === 'true' ? true : false;
}

export const setShowExampleProjectsSetting = (value) => {
  localStorage.setItem(storageKeys.SHOW_EXAMPLE_PROJECTS, JSON.stringify(value));
}
