export const IMAGERY_TYPE_BASEMAP = "BASEMAP";
export const IMAGERY_TYPE_REFERENCE = "REF";
export const IMAGERY_TYPE_NATURAL_COLOR = "NC";
export const IMAGERY_TYPE_SINGLE_BAND = "SB";

// export const TILE_DOWNLOAD_BOUNDARY_MAP = "MAP";
// export const TILE_DOWNLOAD_BOUNDARY_PROJECT = "PROJ";

export const ADDLDATA_TYPE_COMMENT = "COMMENT";
export const ADDLDATA_TYPE_MEDIA = "MEDIA";
export const ADDLDATA_TYPE_FEEDBACK_MEDIA = 'FEEDBACK_MEDIA';

export const DATE_RANGE_SELECTION_LATEST = "LATEST";
export const DATE_RANGE_SELECTION_CUSTOM = "CUSTOM";

export const FILE_UPLOAD_LIMIT = 10485760;

export const CURRENT_VERSION = "0.7.0";

export const CACHING_ZOOM_LEVELS = {
    overview: [10,11,12,13,14,15,16],
    detail: [17,18,19,20]
    }

export const CACHE_THRESHOLD_RANGE = {
    overview: {
        start: 100,
        stop: 1000,
        step: 100
    },
    detail: {
        start: 100,
        stop: 1000,
        step: 50
    }
}

// Default values for values changable by the user, and stored in the localStorage.
export const CACHE_THRESHOLD_DEFAULTS = {
    // Batch Download Alert Thresholds
    // Distance around an alert to include imagery (measured in meters.)
    // Increase this value to increase the number of images to download.
    overview: {
        basemap: 500,
        reference: 500
    },
    detail: {
        basemap: 200,
        reference: 200,
        naturalColor: 150,
        analysis: 150
    }
}
