import React from  'react';
import { connect } from 'react-redux';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import FeedbackComment from './FeedbackComment';

const useStyles = makeStyles(theme => ({
    CommentPane : {
        height: 'calc(100vh - 10.5rem)',
        padding: '5px',
        overflowY: 'scroll',
        backgroundColor: 'rgba(43, 57, 144, 0.25)',
      },
}))


function FeedbackComments(props) {
    const classes = useStyles();
    const {feedback: comments, feedback_comments, feedback} = props;
    const addedFeedbackComments = feedback_comments.filter(comment => comment.fbguid == feedback.properties.guid)
    const filteredAddedFeedbackComments = addedFeedbackComments.filter(comment => !comments.comments.some(existingComment => existingComment.id == comment.id))
    const allComments = [...comments.comments, ...filteredAddedFeedbackComments]
                        .sort((a, b) => new Date(b.created) - new Date(a.created))

    
    // const CommentsList = <Typography variant='body2'>No comments were found.</Typography>

    return (
        <div className={classes.CommentPane}>
            { allComments.length === 0 && <Typography variant='body2'>No comments were found.</Typography> }
            { allComments.length > 0 && allComments.map(comment => (
                <FeedbackComment key={comment.id} comment={comment}/>
            )) }
        </div>
    )
}

// Map Redux state to component props
const mapStateToProps = (state) => ({
    feedback_comments: state.comment_media.feedback_comments,
});

export default connect(mapStateToProps)(FeedbackComments);