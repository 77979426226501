import * as actionTypes from "../actions/actionTypes";
import { updateObject } from "../utility";

const initialState = {
  alerts: [],
  feedback: [],
  selectedAlert: null,
  loading: false,
  error: null
};

const fetchAlertsStart = (state, action) => {
  return updateObject(state, {
    loading: true,
    error: null
  });
};

const fetchAlertsSuccess = (state, action) => {
  return updateObject(state, {
    alerts: action.alerts,
    loading: false,
    error: null
  });
};

const fetchAlertsFailed = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: action.error
  });
};

const saveAlertSuccess = (state, action) => {
  return updateObject(state, {
    alerts: state.alerts.map(item => {
      return item.id === action.alert.id ? action.alert : item;
    }),
    loading: false,
    error: null
  });
};

const selectAlertSuccess = (state, action) => {
  return updateObject(state, {
    selectedAlert: action.alert
  });
};

const fetchFeedbackSuccess = (state, action) => {
  return updateObject(state, {
    feedback: action.feedback,
  })
}

const createFeedbackSuccess = (state, action) => {
  return updateObject(state, {
    feedback: [...state.feedback, action.payload],
    loading: false,
    error: null
  });
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.FETCH_ALERTS_START:
      return fetchAlertsStart(state, action);
    case actionTypes.FETCH_ALERTS_SUCCESS:
      return fetchAlertsSuccess(state, action);
    case actionTypes.FETCH_ALERTS_FAILED:
      return fetchAlertsFailed(state, action);
    case actionTypes.SAVE_ALERT_SUCCESS:
      return saveAlertSuccess(state, action);
    case actionTypes.SELECT_ALERT_SUCCESS:
      return selectAlertSuccess(state, action);
    case actionTypes.FETCH_FEEDBACK_SUCCESS:
      return fetchFeedbackSuccess(state, action);
    case actionTypes.CREATE_FEEDBACK_SUCCESS:
      return createFeedbackSuccess(state, action);
    default:
      return state;
  }
};

export default reducer;
