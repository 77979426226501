import React from 'react';
import { makeStyles } from '@material-ui/core';
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import moment from "moment";
import FeedbackNavigation from './FeedbackNavigation';



const useStyles = makeStyles((theme) => ({
    header: {
        height: "3rem",
        backgroundColor: "rgba(0, 0, 0, 0)",
        flexDirection: "row",
        flexWrap: "nowrap",
        display: "flex",
        alignItems: "center",
      },
      icon: {
        display: "flex",
        alignItems: "center",
      },
      main: {
        width: "100%",
        padding: "5px",
        flexDirection: "column",
        display: "flex",
      },
      product: {
        width: "100%",
      },
      alignRight: {
        textAlign: "right",
      },
      subHeader: {
        fontSize: "x-small",
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
      }
}));

export default function Header(props) {
    const classes = useStyles();
    const {feedback, closeFeedbackContainer, changeTab} = props;
    const submission_date = moment(feedback.properties.date).format("ll");
    const geom = feedback ? L.geoJSON(feedback).getBounds().getCenter() : null;

    return (
      <>
        {/* Information Header */}
        <div className={classes.header}>
          <div className={classes.icon}>
            <KeyboardArrowLeft
              onClick={closeFeedbackContainer}
              fontSize="large"
            />
          </div>
          <div className={classes.main}>
            <div className={classes.product}>
              <strong>Custom Feedback Point</strong>
            </div>
            <div className={classes.subHeader}>
              <div>
                <strong>ID: </strong>
                {feedback.id}
              </div>
              <div>
                <strong>Date: </strong>
                {submission_date}
              </div>
              <div>
                <strong>Lat/Lng: </strong>
                {geom ? geom.lat.toFixed(4) : 0} /{" "}
                {geom ? geom.lng.toFixed(4) : 0}
              </div>
            </div>
          </div>
        </div>
      </>
    );
}