import React, {
  Component
} from "react";
import {
  connect
} from "react-redux";

import moment from "moment";

import classes from "./LayerControl.css";
import {
  initializeObsRasterLayers,
  initializeRefLayers
} from "../../modules/Map/map";
import MUIButton from "../../components/UI/Button/MUIButton";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Legend from "../../components/Legend/Legend";
import { MAIN_VIEW_MAP } from "../../constants/contentViews";

class LayerControl extends Component {
  constructor(props) {
    super(props);

  }
  state = {
    mapLayers: [],
    toggleHeatmap: false,
    mapInitialized: false,
    toggleFeedback: true
  };

  componentDidMount() {
    let dataLayers = [];
      if (this.props.observation) {
        dataLayers = initializeObsRasterLayers(
          this.props.observation,
          this.props.projects[0],
          this.props.renderers
        );
      }

      const refLayers = initializeRefLayers(this.props.refLayers, this.props.projects);

      this.setState({
        mapLayers: dataLayers.concat(refLayers)
      });
    
  }

  componentDidUpdate() {

    if (
      this.state.mapLayers.length > 0 &&
      this.props.map &&
      !this.state.mapInitialized
    ) {

      this.state.mapLayers.forEach(item => {
        if (item.checked) {
          console.log("Adding ", item.title, " to the map.")
          item.addTo(this.props.map);
        }
      });

      this.setState({
        mapInitialized: true
      });

    } else if (this.state.mapLayers.length === 0 &&
      this.props.map &&
      !this.state.mapInitialized && this.props.refLayersReady) {

      const refLayers = initializeRefLayers(this.props.refLayers, this.props.projects);

      this.setState({
        mapLayers: refLayers
      });

    }
  }

  onChangeHandler = event => {
    const _layer = this.state.mapLayers.find(item => {
      return item.dbId === parseInt(event.target.value);
    });

    if (_layer) {
      if (this.props.map.hasLayer(_layer)) {
        console.log("Removing ", _layer.title, " from the map.");
        this.props.map.removeLayer(_layer);
      } else {
        console.log("Adding ", _layer.title, " to the map.");
        _layer.addTo(this.props.map);
      }
    }

    this.setState({
      mapLayers: this.state.mapLayers.map(item => {
        if (item.dbId === parseInt(event.target.value)) {
          item.checked = event.target.checked;
        }
        return item;
      })
    });
  };

  onFeedbackChangeHandler = () => {
    if (this.state.toggleFeedback) {
      this.setState({
        toggleFeedback: false
      })
      this.props.map.removeLayer(this.props.feedbackLayer);
    } else {
      this.setState({
        toggleFeedback: true
      })
      this.props.map.addLayer(this.props.feedbackLayer);
    }
  }

  onHeatmapChangeHandler = () => {
    if (this.props.map.hasLayer(this.props.heatmapLayer)) {
      this.setState({
        toggleHeatmap: false
      })
      this.props.map.addLayer(this.props.obsLayer);
      this.props.map.removeLayer(this.props.heatmapLayer);
    } else {
      this.setState({
        toggleHeatmap: true
      })
      this.props.map.addLayer(this.props.heatmapLayer);
      this.props.map.removeLayer(this.props.obsLayer);
    }
  }

  render() {
    const basemapLayers = this.state.mapLayers.filter(item => {
      return item.type === "BM";
    });
    const refLayers = this.state.mapLayers.filter(item => {
      return item.type === "RF";
    });
    const ncLayers = this.state.mapLayers.filter(item => {
      return item.type === "NC";
    });
    const sbLayers = this.state.mapLayers.filter(item => {
      return item.type === "SB";
    });

    const feedbackcontrol = (
      <React.Fragment key="feedbackcontrol">
            <div className="feedbackcontorl">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={this.state.toggleFeedback}
                    value="feeddbackcontrol"
                    onChange={this.onFeedbackChangeHandler}
                  />
                }
                label="Feedback"
              />
            </div>
          </React.Fragment>
    )

    const heatmap = (
          <React.Fragment key="heatmap">
            <div className="heatmap">
              <FormControlLabel
                control={
                  <Checkbox
                    checked={this.state.toggleHeatmap}
                    value="heatmap"
                    onChange={this.onHeatmapChangeHandler}
                  />
                }
                label="Heatmap"
              />
            </div>
          </React.Fragment>
        );

    const bmtoc = basemapLayers
      .sort((a, b) => (a.displayOrder > b.displayOrder ? 1 : -1))
      .map(item => {
        return (
          <React.Fragment key={item.dbId}>
            <div className={classes.Layer}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={item.checked}
                    value={item.dbId.toString()}
                    onChange={this.onChangeHandler}
                  />
                }
                label={decodeURI(item.title)}
              />
            </div>
          </React.Fragment>
        );
      });
    const reftoc = refLayers
      .sort((a, b) => (a.displayOrder > b.displayOrder ? 1 : -1))
      .map(item => {
        return (
          <React.Fragment key={item.dbId}>
            <div className={classes.Layer}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={item.checked}
                    value={item.dbId.toString()}
                    onChange={this.onChangeHandler}
                  />
                }
                label={decodeURI(item.title)}
              />
              <Legend legend={item.legend} />
            </div>
          </React.Fragment>
        );
      });
    const nctoc = ncLayers
      .sort((a, b) => (a.displayOrder > b.displayOrder ? 1 : -1))
      .map(item => {
        return (
          <React.Fragment key={item.dbId}>
            <div className={classes.Layer}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={item.checked}
                    value={item.dbId.toString()}
                    onChange={this.onChangeHandler}
                  />
                }
                label={decodeURI(item.title)}
              />
            </div>
          </React.Fragment>
        );
      });
    const sbtoc = sbLayers
      .sort((a, b) => (a.displayOrder > b.displayOrder ? 1 : -1))
      .map(item => {
        return (
          <React.Fragment key={item.dbId}>
            <div className={classes.Layer}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={item.checked}
                    value={item.dbId.toString()}
                    onChange={this.onChangeHandler}
                  />
                }
                label={decodeURI(item.title)}
              />

              <Legend legend={item.legend} />
            </div>
          </React.Fragment>
        );
      });
    return (
      <div className={classes.LayerControlPane}>
        <p>Layer Management</p>
        <div className={classes.Layers}>
          {nctoc}
          {sbtoc}
          {bmtoc}
          {reftoc}
          {this.props.projects[0].products[0].package_code === "PUVEGCLEARNC" 
            && this.props.currentView === MAIN_VIEW_MAP && heatmap}
          {feedbackcontrol}
        </div>
        <MUIButton clicked={this.props.cancel} label="Close" color="primary" />
      </div>
    );
  }
}
const mapStateToProps = (state, ownProps) => {
  const renderers = [];
  if (ownProps.observation) {
    const {
      observation
    } = ownProps;
    if (observation.properties.obs_result_renderer) {
      renderers.push(observation.properties.obs_result_renderer);
    }
    // Not used in pwa for now
    // if (observation.properties.prv_result_renderer) {
    //   renderers.push(observation.properties.prv_result_renderer);
    // }
    // if (observation.properties.diff_result_renderer) {
    //   renderers.push(observation.properties.diff_result_renderer);
    // }
  }
  let projects = [];
  if (ownProps.observation) {
    projects.push(
      state.projects.projects.find(
        item => item.id === ownProps.observation.properties.project_id
      )
    );
  } else {
    projects = state.projects.projects.filter(project => {
      return project.checked
    });
    if (projects.length === 0) {
      projects = state.projects.projects
    }
  }

  return {
    projects: projects,
    renderers: renderers,
    refLayers: {
      basemaps: state.map.baseLayers,
      overlays: state.map.refLayers
    },
    refLayersReady: (state.map.baseLayersLoaded && state.map
      .refLayersLoaded),
    token: state.auth.token
  };
};

const mapDisptachToProps = dispatch => {
  return {};
};
export default connect(
  mapStateToProps,
  mapDisptachToProps
)(LayerControl);
