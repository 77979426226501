import React, {useState, useEffect} from  'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import { MarkerClusterGroup } from "leaflet.markercluster";
import { getMapZoomCenter } from '../../../store/localStorage';
import { addMarkers, initializeMap, initializeRefLayers } from '../../../modules/Map/map';

const useStyles = makeStyles(theme => ({
    map: {width: '100%', height: "calc(100% - 7.5rem)",},
    active: { display: 'block', height: 'calc(100% - 4rem)',},
    inactive: {display: 'none', },
    bookmark_button: {},
}))

function FeedbackMap(props) {
    const {feedback} = props;
    const classes = useStyles();
    const [map, setMap] = useState(null);

    console.log('Feedback Map', {basemaps: props.basemaps})

    // Initializing the feedback map
    useEffect(() => {
        const feedback_map = initializeMap('FeedbackMap', getMapZoomCenter());
        const feedback_with_style = {
            ...feedback,
            marker_style: { color: "#b31ec7", radius: 10 } 
        }

        const feedback_layer = new MarkerClusterGroup({
            chunkLoading: true,
            showCoverageOnHover: false,
            disableClusteringAtZoom: 17,
        })
        const feedback_marker = addMarkers([feedback_with_style], 'FeedbackMap', () => {})
        feedback_layer.addLayers(feedback_marker);

        // Add in the reference Layers
        const basemaps_and_reference_layers = initializeRefLayers(props.base_and_reference, []);
        console.log(basemaps_and_reference_layers);

        basemaps_and_reference_layers.forEach(layer => layer.addTo(feedback_map));
                
        
        feedback_layer.addTo(feedback_map)
        // props.basemaps.forEach(basemap => basemap.addTo(feedback_map));
        // props.overlays.forEach(overlay => overlay.addTo(feedback_map));
        setMap(feedback_map);

    }, [])

    // Initialize Map  
    // if (map === null) {
    //     const feedback_map = initializeMap('FeedbackMap');

    //     const feedback_layer = new MarkerClusterGroup({
    //         chunkLoading: true,
    //         showCoverageOnHover: false,
    //         disableClusteringAtZoom: 17,
    //     })
    //     const feedback_marker = addMarkers([feedback], 'FeedbackMap', () => {})
    //     feedback_layer.addLayers(feedback_marker);

    //     feedback_layer.addTo(feedback_map)



    //     setMap(feedback_map);
    // }
    


    return (
        <div className={classes.map} id='FeedbackMap'></div>
    )
}


const mapStateToProps = (state, own_props) => {
    const basemaps = state.map.baseLayers.filter(bm => bm.name === 'osm');
    
    console.log('%cFeedback Map Basemaps', 'color: teal', {basemaps});
    return ({
        base_and_reference: {
            basemaps: basemaps,
            overlays: []
        }
    })
};

const mapDispatchToProps = (dispatch) => ({

});

export default connect(mapStateToProps, mapDispatchToProps)(FeedbackMap);