import React, { useState } from'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import { createFeedbackComment } from '../../../store/actions/alerts';

const useStyles = makeStyles((theme) => ({
    comment_form: {
        margin: '1rem',
    },
    text_field: {
        width: '100%',
    }
}));

function FeedbackCommentForm(props) {
    const classes = useStyles();
    const [new_comment, setNewComment] = useState('');

    const handleChange = (e) => {
        setNewComment(e.target.value);
    }

    const submitHandler = (e) => {
        e.preventDefault();
        const stamp = new Date()
        const feedback_payload = {
            data: {
                id : stamp.getTime(),
                unsynced: props.feedback.unsynced || false,
                fbguid: props.feedback.properties.guid,
                comment: new_comment,
            }
        }
        
        console.log('Feedback Comment Form Payload', feedback_payload)
        props.createFeedbackComment(feedback_payload)
        props.cancel();
    }

    return <form onSubmit={submitHandler} className={classes.comment_form}>
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <TextField
                className={classes.text_field}
                id="comment"
                name="comment"
                label="Comments"
                multiline
                minRows={4}
                value={new_comment}
                onChange={handleChange}
                />
            </Grid>
            <Grid item xs={12}>
                <Button variant='contained' color='primary' type='submit'>
                    Submit
                </Button>
                <Button 
                    variant="contained" 
                    color="default" 
                    onClick={props.cancel}>
                    Cancel
                </Button>
            </Grid>
        </Grid>
    </form>
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => {
    return {
        createFeedbackComment: comment => dispatch(createFeedbackComment(comment)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(FeedbackCommentForm)