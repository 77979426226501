import api from '../../api/axios-api';
import db from '../db';
import store from '../index'

export const createFeedback = async (sync_item) => {
    if ('data' in sync_item) {
        sync_item = sync_item.data;
    }
    // feedback: {submitter, date, comments[], media[], package_code, latitude, longitude }
    const {feedback, comments, media: photos} = sync_item;
    console.log('createFeedback', feedback);
    const payload = {
        submitter: feedback.submitter,
        package_code: feedback.package_code,
        latitude: feedback.latitude,
        longitude: feedback.longitude,
    }
    if ('guid' in feedback) {
        payload["guid"] = feedback.guid;
    }
    const response = await api.post('/feedback', payload)
    if (response.status == 201) {
        const {data} = response;
        const feedback_id = data.properties.guid;

        db.table("feedback").filter(item => item.properties.guid === feedback_id).toArray().then(
            feedbacks => {
                console.log(feedbacks)
                if (feedbacks.length == 0) {
                    db.table("feedback").add(data)
                    console.log("A new feedback " + data.id + " has been added to IndexedDB.")
                } else {
                    db.table("feedback").update(feedbacks[0].id, data).then(updated => {
                        if (updated) {
                          console.log("The existing feedback " + feedbacks[0].id + " has been replaced with a new feedback " + data.id + " in IndexedDB.");
                        } else {
                          console.log("Failed to update the feedback " + feedbacks[0].id + " in IndexedDB.");
                        }
                      });
                }
            }
        )
        console.log('Received response for feedback', response);
        console.log(`Comments (${comments.length}) | Media (${photos.length})`)

        comments.forEach(comment => {
            comment.fbguid = feedback_id;
            createFeedbackComment({data: comment});
        })

        photos.forEach(photo => {
            photo.fbguid = feedback_id;
            createFeedbackMedia({data: photo});
        })
    }
    return response
    // await api.post('/feedback', payload).then(response => {
    // }).catch(error => {
    //     console.log('Error: submitting feedback', error);
    // })
}

export const createFeedbackMedia = async (sync_item) => {
    const media = sync_item.data;
    console.log('createFeedbackMedia', media);
    const form_data = new FormData();
    ['file', 'comment', 'fbguid', 'capture_date'].forEach(key => {
        if (key in media && media[key]) {
            form_data.append(key, media[key])
        } else if (key == 'capture_date') {
            if ('created' in media) {
                form_data.append(key, media['created'])
            }
        }
    })
    const request_options = { headers: {'Content-Type': undefined}};

    return api.post(`/v4/feedback/${media.fbguid}/media`, form_data, request_options)
        // .then(response => {
        //     console.log('Created media for feedback! ' + media.fbguid, response)
        //     return response;
        // }).catch(error => {
        //     console.log('Error Creating media for feedback: ' + media.fbguid, error)
        //     return error;
        // })
}

export const createFeedbackComment = async (sync_item) => {
    // https://api.dev.satelytics.io/3ba99330-79f8-4e88-b0fe-97aad8f4c955/api/v4/feedback/17/comments
    // Payload
    // comment, feedback(id)
    // Response
    // id, observation, feedback, obsguid, fbguid, memberid, username, comment, has_media: false, created, modified
    const payload = sync_item.data;
    const feedback_id = sync_item.data.fbguid;

    console.log('About to send feedback comment request', payload);

    return api.post(`/v4/feedback/${feedback_id}/comments`, payload)
        // .then(response => {
        //     console.log('Created comment for feedback!'+ feedback_id, response)
        // }).catch(error => {
        //     console.log('Error Creating comment for feedback:'+ feedback_id, error)
        //     // Error: { "error": "Invalid Feedback ID" }
        // });
}


// Gathers the feedbacks comments, media, and associated imagery.
export const getFeedbackDetails = async (feedback) => {
    const { guid } = feedback.properties;
    const online = store.getState().app.online;

    // if offline just check dexie and return { comments: [], media: [] }
    if (!online) {

        try {
            const comments = await db.table('feedback_comments').where({fbguid: guid}).toArray();
            const media = await db.table('feedback_media').where({fbguid: guid}).toArray();
    
            return { comments, media};
        } catch (error) {
            console.log('error finding feedback details from cache', error);
            return { comments: [], media: []}
        }
    }

    // else send a request

    const comment_promise = api.get(`/v4/feedback/${guid}/comments`)
    const media_promise = api.get(`/v4/feedback/${guid}/media`)


    return Promise.all([comment_promise, media_promise]).then((responses) => {
        const [comment_response, media_response] = responses;

        console.log('Responses!', {commments: comment_response.data, media: media_response.data});
        
        db.table('feedback_comments').bulkPut(comment_response.data);
        db.table('feedback_media').bulkPut(media_response.data);

        return {
            comments: comment_response.data,
            media: media_response.data,
        }
    }).catch(error => {
        console.log('Error fetching comments/media', error);
    })
}
