import React from 'react';
import { makeStyles } from '@material-ui/core';
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import IconButton from "@material-ui/core/IconButton";
import { Delete } from '@material-ui/icons';
import Typography from "@material-ui/core/Typography";
import moment from "moment";

const useStyles = makeStyles(theme => ({
    comment_container: {
        border: '1px solid black',
        padding: '20px',
        background: 'white',
        marginTop: theme.spacing(2),
    },
    root: {
        padding: 0
    },
    dateContainer: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
}));

export default function FeedbackComment(props) {
    const {comment} = props;
    const classes = useStyles();

    return <Box className={classes.comment_container}>
        <Grid container spacing={2}>
            {/* date, submitter, comment, */}
            <Grid item xs={12}>
                <Typography variant="body1">{comment.comment}</Typography>
            </Grid>
            <Grid item xs={12} className={classes.dateContainer}>
                <Typography variant="caption" className={classes.date}>{moment(comment.created).format("lll")}{" "}{comment.username}</Typography>
                <IconButton
                    className={classes.root}
                    color="inherit"
                    onClick={() => {console.log("DELETE Comment")}}
                >
                    <Delete fontSize="small" />
                </IconButton>
            </Grid>
        </Grid>
    </Box>
}