import * as actionTypes from "./actionTypes";
import axios from "../../api/axios-api";
import {
  replaceTableInDB
} from "../utility";
// import { updateAlertSelection } from "./alerts";
import db from "../db";
import store from "../../store/index"
import SioLogger from "../../modules/Logger/logger";
import { persistLookupFilterSettings } from "./filters";


const logger = SioLogger('src/store/actions/projects.js');

const fetchProjectsSuccess = projects => {
  return {
    type: actionTypes.FETCH_PROJECTS_SUCCESS,
    data: projects
  };
};
const fetchProjectsFailed = error => {
  return {
    type: actionTypes.FETCH_PROJECTS_FAILED,
    error: error
  };
};

const fetchProjectsStart = () => {
  return {
    type: actionTypes.FETCH_PROJECTS_START
  };
};

export const fetchProjects = () => {
  return dispatch => {
    dispatch(fetchProjectsStart());
    return axios
      .get("/v2/projects")
      .then(async response => {
        await persistLookupFilterSettings(response, 'projects');
        dispatch(fetchProjectsSuccess(response.data));
        replaceTableInDB("projects", response.data);
        return response.data;
      })
      .catch(error => {
        logger.error('Error fetching projects.', error);
        dispatch(fetchProjectsFailed(error));
      });
  };
};
//get data from local indexedDB
export const refreshProjects = () => {
  return dispatch => {
    dispatch(fetchProjectsStart());
    db.projects
      .toArray()
      .then(response => {
        dispatch(fetchProjectsSuccess(response));
      })
      .catch(error => {
        logger.error('Error refreshing projects.', error);
        dispatch(fetchProjectsFailed(error));
      });
  };
};

//check filters on project, needs to update alert as well
export const checkProjects = selected => {
  return dispatch => {
    let projects = store.getState()
      .projects.projects.map(project => {
        project.checked = selected.indexOf(project.id) >= 0;
        return project;
      });
    replaceTableInDB("projects", projects);
    dispatch(fetchProjectsSuccess(projects));
    // dispatch(updateAlertSelection());
  };
};

export const setActivePackage = (active_package) => {
  return {
    type: actionTypes.SET_ACTIVE_PACKAGE,
    active_package
  }
}
