import React, {
  PureComponent
} from "react";
import {
  connect
} from "react-redux";
import { withRouter } from "react-router-dom";
import {
  withStyles
} from '@material-ui/core/styles';
import * as Actions from "../../store/actions/index";
import MUIButton from "../../components/UI/Button/MUIButton";
import DatePickers from "../../components/UI/DatePicker/DatePicker";
import CheckboxesGroup from "../../components/UI/CheckboxGroup/CheckboxGroup";
import RadioButtonsGroup from "../../components/UI/RadioGroup/RadioGroup";
import SimpleSelect from "../../components/UI/SimpleSelect/SimpleSelect";
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { Switch as MuiSwitch } from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import Divider from "@material-ui/core/Divider";
import { isAuthenticated, isOnline,isDarkMode, isLoading } from "../../store/selectors/app"
import {
  validateDateRange
} from "../../api/functions";
import {
  getDateRangeSelection,
  setDateRangeSelection,
  getSettings,
  setSettings,
  getGPSSettings,
  setGPSSettings,
  getDarkMode,
  setDarkMode,
  getOfflineMode,
  setOfflineMode,
  getAppInitialized,
  setAppInitialized,
  getThresholdSettings,
  setThresholdSettings,
  getShowExampleProjectsSetting,
  setShowExampleProjectsSetting,
} from "../../store/localStorage/index";
import * as settings from "../../constants/settings";
import ThresholdSettingsForm from "./ThresholdSettingsForm";
import SioLogger from "../../modules/Logger/logger";
import db from "../../store/db";
import CircularProgress from '@material-ui/core/CircularProgress';

const logger = SioLogger('containers/Settings/Settings.js');

const styles = theme => ({
  snackbar: {
    top:"3.0rem",
  },
});
class Settings extends PureComponent {
  constructor (props) {
    super(props);
    const savedSettings = getSettings();
    const drsSettings = getDateRangeSelection();
    const darkMode = getDarkMode();
    const offlineMode = getOfflineMode();
    const gpsSettings = props.gpsSettings;
    const appInitialized = getAppInitialized();
    
    const initializeDateRangeSelection = (drsSettings) => {
      const initialState = [{
          id: settings.DATE_RANGE_SELECTION_LATEST,
          label: "Last 180 days from latest observation"
        },
        {
          id: settings.DATE_RANGE_SELECTION_CUSTOM,
          label: "Custom Date Range"
        }
      ]
      let updatedDRS = this.updateLocal(
        initialState,
        drsSettings.id
      );

      const idx = updatedDRS.findIndex(item => {
        return item.id === settings.DATE_RANGE_SELECTION_CUSTOM;
      });
      updatedDRS[idx].startDate = drsSettings.startDate;
      updatedDRS[idx].endDate = drsSettings.endDate;

      return updatedDRS;
    }

    // const initializeTileDownloadBoundary = (savedSettings) => {
    //   const initialState = [{
    //       id: settings.TILE_DOWNLOAD_BOUNDARY_MAP,
    //       label: "Use Current Map Extent"
    //     },
    //     {
    //       id: settings.TILE_DOWNLOAD_BOUNDARY_PROJECT,
    //       label: "Use Project Boundary"
    //     }
    //   ];
    //   return this.updateLocal(
    //     initialState,
    //     savedSettings.selectedTDB
    //   )
    // }

    const initializeImageryTypes = (savedSettings) => {
      const initialState = [{
          id: settings.IMAGERY_TYPE_BASEMAP,
          name: "Open Street Maps"
        },
        {
          id: settings.IMAGERY_TYPE_NATURAL_COLOR,
          name: "Natural Color"
        },
        {
          id: settings.IMAGERY_TYPE_SINGLE_BAND,
          name: "Analysis"
        },
        {
          id: settings.IMAGERY_TYPE_REFERENCE,
          name: 'Reference'
        }
      ];

      return this.updateLocal(
        initialState,
        savedSettings.imageryTypes
      )
    }

    const initializeAddlDataTypes = (savedSettings) => {
      const initialState = [{
          id: settings.ADDLDATA_TYPE_COMMENT,
          name: "Alert Comments"
        },
        {
          id: settings.ADDLDATA_TYPE_MEDIA,
          name: "Alert Photos"
        },
        {
          id: settings.ADDLDATA_TYPE_FEEDBACK_MEDIA,
          name: 'Feedback Photos',
        }
      ];

      return this.updateLocal(
        initialState,
        savedSettings.addlDataTypes
      )
    }

    this.state = {
      settings: savedSettings,
      projects: [],
      dateRangeSelection: initializeDateRangeSelection(drsSettings),
      // tileDownloadBoundary: initializeTileDownloadBoundary(savedSettings),
      // maxZoomLevels: initializeMaxZoomLevels(savedSettings),
      imageryTypes: initializeImageryTypes(savedSettings),
      addlDataTypes: initializeAddlDataTypes(savedSettings),
      darkMode: darkMode,
      offlineMode: offlineMode,
      gpsSettings: gpsSettings,
      appInitialized: appInitialized,
      thresholdSettings: getThresholdSettings(),
      syncLoading: false,
      summaryVisible: false,
      summary: "",
      showExampleProjects: getShowExampleProjectsSetting()
    };
  };

  barCancelHandler = () => {
    this.setState({
      summaryVisible: false,
    })
  }

  updateLocal = (source, selectedValue) => {
    return source.map(item => {
      if (selectedValue instanceof Array) {
        if (selectedValue.indexOf(item.id) > -1) {
          item.checked = true;
        } else {
          item.checked = false;
        }
      } else {
        if (item.id === selectedValue) {
          item.checked = true;
        } else {
          item.checked = false;
        }
      }
      return item;
    });
  };

  // componentDidMount = () => {
  //   if (this.state.projects.length === 0) {
  //     if (this.props.projects.length === 0) {
  //       this.props.onFetchProjects()
  //         .then(data => {
  //           this.setState({
  //             projects: this.updateLocal(JSON.parse(JSON.stringify(
  //                 data)), this.state.settings
  //               .selectedProjects)
  //           });
  //         });
  //     } else {
  //       this.setState({
  //         projects: this.updateLocal(JSON.parse(JSON.stringify(this.props
  //             .projects)), this.state
  //           .settings
  //           .selectedProjects)
  //       });
  //     }
  //   }
  // };

  refreshDataHandler = event => {
    event.preventDefault();
    this.props.onFetchAlerts();
    this.props.onFetchProjects();
    this.props.onFetchLookups();
    this.props.onFetchBaseLayers();
    this.props.onFetchRefLayers();
  }

  dateRangeSelectorHandler = event => {
    this.setState({
      dateRangeSelection: this.updateLocal(
        this.state.dateRangeSelection,
        event.target.value
      )
    });
  };

  customDRSDatesHandler = event => {
    const updatedDRSEntry = this.state.dateRangeSelection.find(item => {
      return item.id === settings.DATE_RANGE_SELECTION_CUSTOM;
    });

    if (event.target.getAttribute("id") === "start-date") {
      updatedDRSEntry.startDate = event.target.value;
    }
    if (event.target.getAttribute("id") === "end-date") {
      updatedDRSEntry.endDate = event.target.value;
    }

    this.setState({
      dateRangeSelection: this.state.dateRangeSelection.map(item => {
        return item.id === settings.DATE_RANGE_SELECTION_CUSTOM ?
          updatedDRSEntry :
          item;
      })
    });
  };

  // tileDownloadBoundarySelectorHandler = event => {
  //   if (event.target.value === settings.TILE_DOWNLOAD_BOUNDARY_MAP) {
  //     this.setState({
  //       tileDownloadBoundary: this.updateLocal(
  //         this.state.tileDownloadBoundary,
  //         event.target.value
  //       ),
  //       projects: this.state.projects.map(item => {
  //         item.checked = false;
  //         return item;
  //       })
  //     });
  //   } else {
  //     this.setState({
  //       tileDownloadBoundary: this.updateLocal(
  //         this.state.tileDownloadBoundary,
  //         event.target.value
  //       )
  //     });
  //   }
  // };

  // tileDownloadBoundaryProjectSelectorHandler = event => {
  //   this.setState({
  //     projects: this.state.projects.map(item => {
  //       if (item.id === parseInt(event.target.value)) {
  //         item.checked = event.target.checked;
  //       }
  //       return item;
  //     })
  //   });
  // };

  imageTypeHandler = event => {
    this.setState({
      imageryTypes: this.state.imageryTypes.map(item => {
        if (item.id === event.target.value) {
          item.checked = event.target.checked;
        }
        return item;
      })
    });
  };

  addlDataTypeHandler = event => {
    this.setState({
      addlDataTypes: this.state.addlDataTypes.map(item => {
        if (item.id === event.target.value) {
          item.checked = event.target.checked;
        }
        return item;
      })
    });
  };

  saveDROHandler = event => {
    let DRS = getDateRangeSelection();
    let selectedDRS = this.state.dateRangeSelection.find(item => {
      return item.checked === true;
    });
    DRS.id = selectedDRS.id;
    if (DRS.id === settings.DATE_RANGE_SELECTION_CUSTOM) {
      DRS.startDate = selectedDRS.startDate;
      DRS.endDate = selectedDRS.endDate;
    }
    setDateRangeSelection(DRS);
    validateDateRange()
      .then(newDates => {
        this.props.onFetchAlerts();
        if (DRS.id === settings.DATE_RANGE_SELECTION_LATEST) {
          selectedDRS = this.state.dateRangeSelection.find(item => {
            return item.id === settings.DATE_RANGE_SELECTION_CUSTOM;
          });
          selectedDRS.startDate = newDates.startDate;
          selectedDRS.endDate = newDates.endDate;
          this.setState({
            dateRangeSelection: this.state.dateRangeSelection.map(
              item => {
                return item.id === settings
                  .DATE_RANGE_SELECTION_CUSTOM ?
                  selectedDRS :
                  item;
              })
          });     
        }   
      });    
      if (!this.state.appInitialized){
        setAppInitialized(true);
        this.setState({
          appInitialized: true
        });
        // Navigate to Project screen
        this.props.history.push("/")      
      }
  };


  saveOIOHandler = event => {
    const updatedSettings = {
      // selectedTDB: settings.TILE_DOWNLOAD_BOUNDARY_MAP,
      imageryTypes: [],
      addlDataTypes: [],
      concurrentRequests: 8
    };

    

    // for (let i = 0; i < this.state.tileDownloadBoundary.length; i++) {
    //   if (this.state.tileDownloadBoundary[i].checked) {
    //     updatedSettings.selectedTDB = this.state.tileDownloadBoundary[i]
    //       .id;
    //     break;
    //   }
    // }
    // if (
    //   updatedSettings.selectedTDB === settings
    //   .TILE_DOWNLOAD_BOUNDARY_PROJECT
    // ) {
      
    // } 
    for (let i = 0; i < this.state.imageryTypes.length; i++) {
      if (this.state.imageryTypes[i].checked) {
        updatedSettings.imageryTypes.push(this.state.imageryTypes[i].id);
      }
    }
    for (let i = 0; i < this.state.addlDataTypes.length; i++) {
      if (this.state.addlDataTypes[i].checked) {
        updatedSettings.addlDataTypes.push(this.state.addlDataTypes[i]
          .id);
      }
    }
    this.setState({
      settings: updatedSettings
    });
    setSettings(updatedSettings);
    setThresholdSettings(this.state.thresholdSettings);
    alert('Your offline imagery settings have been saved.');
  };

  toggleModeHandler = event => {
    this.props.onModeChange(event.target.checked)
    this.setState({
      darkMode: event.target.checked
    });
    setDarkMode(event.target.checked)
  }

  toggleGPSWatchHandler = event => {
    const _gps = {...this.state.gpsSettings}
    _gps.watch = event.target.checked
    // // Save the changes to local storage
    this.props.onChangeGPSettings(_gps)
    // // Save changes of local state
    this.setState({
      gpsSettings: _gps
    });
    setGPSSettings({gps:_gps})
  }

  toggleGPSAccuracyHandler = event => {

    const _gps = {...this.state.gpsSettings}
    _gps.highAccuracy = event.target.checked
    // // Save the changes to local storage
    this.props.onChangeGPSettings(_gps)
    // // Save changes of local state
    this.setState({
      gpsSettings: _gps
    });
    setGPSSettings({gps:_gps})
  }

  toggleOfflineSetting = event => {
    if( !this.props.online && navigator.onLine) {
      this.setState({
        offlineMode: false,
      });
      setOfflineMode(false)
      this.props.onAppOnline();
      this.props.onCheckState();
      if (!Actions.authTokenExpired()){
        // Start the manual synch process
        // For those clients who don't have
        // Service Worker capabilities
        if (this.props.isAuthenticated) {
          let syncItemsCpy;
          logger.info('Application is back online. Run fallback sync functions...')
          db.table("syncitems").toArray().then(async items => {
            syncItemsCpy = items;
            try {
              this.setState({
                syncLoading: true
              });
              await this.props.onSyncUpdates();
            } catch (err) {
              console.log(err);
              this.setState({
                syncLoading: false,
                summaryVisible: true,
                summary: "Unable to sync data. " + err
              })
              return;
            }
            if (syncItemsCpy.length > 0) {
              logger.info("Generating summary...");
              let obsCount = 0, commentCount = 0, mediaCount = 0;
              syncItemsCpy.forEach(item => {
                switch (item.type) {
                  case "OBSERVATION":
                    obsCount++;
                    break;
                  case "COMMENT":
                    commentCount++;
                    break;
                  case "MEDIA":
                    mediaCount++;
                    break;
                  default:
                    break;
                }
              });
              this.setState({
                syncLoading: false,
                summaryVisible: true,
                summary: `Successfully synchronized ${obsCount ? `alerts: ${obsCount}` : ''} ${commentCount ? `comments: ${commentCount}` : ''} ${mediaCount ? `photos: ${mediaCount}` : ''}`
              })
            } else {
              this.setState({
                syncLoading: false,
                summaryVisible: true,
                summary: "Did not have records to synchronize"
              })
            }
          })
        }
      } 
    } 
    else {
      logger.info('Application is going offline.')
      this.setState({
        offlineMode: true
      });
      setOfflineMode(true)
      this.props.onAppOffline();
      
    }    
  }

  // toggleDebugging = event => {
  //   if( this.props.debugEnabled ) {
  //     this.props.onDebugDisabled();
  //   }
  //   else {
  //     this.props.onDebugEnabled();
  //   }
  // }

  updateThresholdSettings = (key, subkey, value) => {
    const oldState = this.state.thresholdSettings;
    const newState = {
      ...this.state.thresholdSettings,
    }
    newState[key][subkey] = value;
    this.setState({
      thresholdSettings: newState
    })
  }

  toggleExampleProjects = () => {
    const originalSetting = getShowExampleProjectsSetting()

    setShowExampleProjectsSetting(!originalSetting);
    this.setState({
      showExampleProjects: !originalSetting,
    })
  }

  render() {
    // const showAppNotIniMessage = (<Snackbar
    //   anchorOrigin={{vertical:'top', horizontal:'center'}}
    //   open={!this.state.appInitialized}
    //   key={'topcenter'}
    //   className = {this.props.classes.snackbar}
    //   >
    //     <Alert variant="filled" severity="warning">
    //       Please set the date range to the desired time period.
    //     </Alert>
    //   </Snackbar>);
    const showAppNotIniMessage = (
        <Alert variant="filled" severity="warning">
          Please set the date range to the desired time period and click "Save".
        </Alert>
      );

    let DRO = null;
    // if (this.state.settings.hasOwnProperty("selectedTDB")) {
    const customEntry = this.state.dateRangeSelection.find(item => {
      return item.id === settings.DATE_RANGE_SELECTION_CUSTOM;
    });
    const customDRS = customEntry.checked ? customEntry : null;
    DRO = (
      <div>
        <div
          style={{
            width: "100%",
            // backgroundColor: "grey",
            fontSize: "1.35em",
            paddingLeft: "1rem"
          }}
        >
          Date Range Options
        </div>
        <div>
          <RadioButtonsGroup
            formLabel="Date Range Option"
            domainValues={this.state.dateRangeSelection}
            defaultValue={
              this.state.dateRangeSelection.find(item => {
                return item.checked === true;
              }).id
            }
            groupName="DRS_Options"
            emitEvent={this.dateRangeSelectorHandler}
          />
        </div>
        {customDRS ? (
          <div
            style={{
              width: "100%",
              // backgroundColor: "grey",
              fontSize: "1.35em",
              paddingLeft: "1rem"
            }}
          >
            <DatePickers
              label={"From Date"}
              id={"start-date"}
              defaultDate={customDRS.startDate}
              emitEvent={this.customDRSDatesHandler}
            />
            <DatePickers
              label={"To Date"}
              id={"end-date"}
              defaultDate={customDRS.endDate}
              emitEvent={this.customDRSDatesHandler}
            />
          </div>
        ) : null}
        <div style={{ textAlign: "center" }}>
          <MUIButton
            clicked={this.saveDROHandler}
            disabled={!this.props.online || this.props.loading}
            label="Save"
            color="primary"
          />
        </div>
      </div>
    );
    // }

    let OIO = null;
    // if (this.state.settings.hasOwnProperty("selectedTDB")) {
    //   let projects = null;
    //   if (
    //     this.state.tileDownloadBoundary.find(item => {
    //       return item.id === settings.TILE_DOWNLOAD_BOUNDARY_PROJECT;
    //     })
    //     .checked &&
    //     this.state.projects.length > 0
    //   ) {
    //     projects = (
    //       <div>
    //         <CheckboxesGroup
    //           formLabel="Projects:"
    //           domainValues={this.state.projects}
    //           labelProperty="project_name"
    //           emitEvent={this.tileDownloadBoundaryProjectSelectorHandler}
    //         />
    //       </div>
    //     );
    //   }
    OIO = (
      <div>
        <div
          style={{
            width: "100%",
            fontSize: "1.35em",
            paddingLeft: "1rem"
          }}
        >
          Offline Options
        </div>

        <div style={{ textAlign: "center" }}>
          {this.state.syncLoading && (
            <CircularProgress size={36} />
          )}
        </div>
        <div style={{ textAlign: "center" }} >
          <MUIButton
            clicked={this.toggleOfflineSetting}
            label={!this.props.online ? "Go Online" : "Go Offline"}
            color={!this.props.online ? "primary" : "secondary"}
            disabled={this.state.syncLoading}
          />
        </div>

        {/* <div>
          <RadioButtonsGroup
            formLabel="Boundary Option"
            domainValues={this.state.tileDownloadBoundary}
            defaultValue={this.state.settings.selectedTDB}
            groupName="TDB_Options"
            emitEvent={this.tileDownloadBoundarySelectorHandler}
          />
        </div>
        {projects} */}
        {/* <div>
          <SimpleSelect
            domainValues={this.state.maxZoomLevels}
            defaultValue={this.state.settings.maxZoomLevel.toString()}
            formLabel="Max Zoom Level"
            emitEvent={this.zoomLevelHandler}
          />
        </div> */}
        <div>
          <ThresholdSettingsForm updateThresholdSettings={this.updateThresholdSettings}/>
        </div>
        <div>
          <CheckboxesGroup
            formLabel="Imagery Types:"
            domainValues={this.state.imageryTypes}
            labelProperty="name"
            emitEvent={this.imageTypeHandler}
          />
        </div>
        <div>
          <CheckboxesGroup
            formLabel="Additional Data:"
            domainValues={this.state.addlDataTypes}
            labelProperty="name"
            emitEvent={this.addlDataTypeHandler}
          />
        </div>
        <div style={{ textAlign: "center" }}>
          <MUIButton
            clicked={this.saveOIOHandler}
            label="Save"
            color="primary"
          />
        </div>
      </div>
    );
    // }

    const basicStyles = { 
      width: '100%',
      fontSize: '1.35em',
      paddingLeft: '1rem'
    };
    

    return (
      <React.Fragment>
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          open={this.state.summaryVisible}
          autoHideDuration={5000}
          onClose={this.barCancelHandler}
        >
          <Alert
            onClose={this.barCancelHandler}
            severity="success"
            variant="filled"
            sx={{ width: "50%" }}
          >
            {this.state.summary}
          </Alert>
        </Snackbar>

        {!this.state.appInitialized ? showAppNotIniMessage : null}
        {DRO}
        <Divider />
        {OIO}
        <Divider />
        <div>
          <div
            style={basicStyles}
          >
            Application
          </div>
          <div
            style={{
              width: "100%",
              display: "none",
              fontSize: "1.35em",
              paddingLeft: "1rem"
            }}
          >
            <FormControlLabel className={this.props.classes.root} label="Dark Mode" control={<MuiSwitch checked={this.state.darkMode} onClick= { this.toggleModeHandler } />} />
          </div>
          <div
            style={basicStyles}
          >
            <FormControlLabel className={this.props.classes.root} label="GPS Continuous Update" control={<MuiSwitch checked={this.state.gpsSettings.watch} onClick= { this.toggleGPSWatchHandler } />} />
          </div>
          <div style={basicStyles}>
            <FormControlLabel className={this.props.classes.root} label="GPS Use High Accuracy" control={<MuiSwitch checked={this.state.gpsSettings.highAccuracy} onClick= { this.toggleGPSAccuracyHandler } />} />
            <div
              style={{
                width: "100%",
                fontSize: "0.8em",
                paddingLeft: "1rem"
              }}
            >*Can result in slower response times or increased power consumption
            </div>
          </div>

          <div style={basicStyles}>
            <FormControlLabel 
              className={this.props.classes.root} label='Show Example Projects' control={<MuiSwitch checked={this.state.showExampleProjects} onClick={this.toggleExampleProjects}/>} 
            />
          </div>

          {/* <div style={basicStyles}>
            <FormControlLabel className={this.props.classes.root} label="Toggle Developer Mode" control={<MuiSwitch checked={this.state.debugEnabled} onClick= { this.toggleDebugging } />} />
          </div> */}

          <div style={{ textAlign: "center" }}>
            <MUIButton
              clicked={this.refreshDataHandler}
              disabled={!this.props.online || this.props.loading}
              color="primary"
              label="Refresh All Data"
            />
          </div>

          <div style={{ textAlign: "center", marginTop: '1rem' }}>
            <MUIButton
              clicked={(() => {this.props.history.push('/debug')})}
              // disabled={this.props.isOffline}
              color="primary"
              label="View Diagnostics"
            />
          </div>
        </div>
        <Divider />
        <div
          style={{
            width: "100%",
            fontSize: "1.0em",
            paddingLeft: "1rem"
          }}
        >
          Version : {settings.CURRENT_VERSION}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    isAuthenticated: isAuthenticated(state),
    darkMode: isDarkMode(state),
    online: isOnline(state),
    loading: isLoading(state),
    // projects: state.projects.projects,
    gpsSettings: state.app.gps,
    // debugEnabled: state.app.debugEnabled
  };
};

const mapDisptachToProps = dispatch => {
  return {
    onFetchAlerts: () => dispatch(Actions.initializeObservations()),
    onFetchProjects: () => dispatch(Actions.fetchProjects()),
    onFetchLookups: () => dispatch(Actions.fetchLookups()),
    onFetchBaseLayers: () => dispatch(Actions.fetchBaseLayers()),
    onFetchRefLayers: () => dispatch(Actions.fetchReferenceLayers()),
    onModeChange: (mode) => dispatch(Actions.toggleThemeMode(mode)),
    onChangeGPSettings: (data) => dispatch(Actions.updateGPSSettings(data)),
    onAppOnline: () => dispatch(Actions.appOnline()),
    onAppOffline: () => dispatch(Actions.appOffline()),
    onSyncUpdates: () => dispatch(Actions.syncUpdates()),
    onDebugEnabled: () => dispatch(Actions.enableDebug()),
    onDebugDisabled: () => dispatch(Actions.disableDebug()),
    onCheckState: () => dispatch(Actions.authCheckState()),
  };
};
export default connect(
  mapStateToProps,
  mapDisptachToProps
)(withStyles(styles)(
  withRouter(Settings)
));
