export const MAIN_VIEW_MAP = "MAIN_VIEW_MAP";
export const MAIN_VIEW_LIST = "MAIN_VIEW_LIST";

export const DETAIL_VIEW_MAP = "DETAIL_VIEW_MAP";
export const DETAIL_VIEW_PROPERTIES = "DETAIL_VIEW_PROPERTIES";
export const DETAIL_VIEW_COMMENTS = "DETAIL_VIEW_COMMENTS";
export const DETAIL_VIEW_PHOTOS = "DETAIL_VIEW_PHOTOS";

export const FEEDBACK_FORM = "FEEDBACK_FORM";
export const FEEDBACK_DETAILS = "FEEDBACK_DETAILS";
