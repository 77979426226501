import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import TAB_VIEWS from "./TAB_VIEWS";

const useStyles = makeStyles(theme => ({
  tab_items: {
    height: '1.5rem',
    margin: '0.1875rem 0',
    padding: 0,
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '5px',
    textAlign: 'center',
    backgroundColor: 'rgba(0,0,0,0)',
  },
  tab_item: {
    padding: '0 0.09375rem 0 0.09375rem',
    width: '100%',
    height: '100%',
    border: '1px solid rgba(0, 0, 0, 0.5)',
    cursor: 'pointer',

    "&.active": {
      backgroundColor: "rgba(42, 85, 164, .75)",
      color: "white"
    }
  }
}));

const TabItem = props => {
  const classes = useStyles();
  const { current_tab, tab, changeTab } = props;
  const is_active = current_tab === tab;
  const active_class = `${classes.tab_item} active`;
  const normal_class = classes.tab_item;

  const clickHandler = () => {
    if (is_active) return; 
    
    changeTab(tab);
  };

  return (
    <div
      className={is_active ? active_class : normal_class}
      onClick={clickHandler}
    >
      {tab}
    </div>
  );
};

// Allows the user to navigate between the different pages of the Feedback View.
export default function FeedbackNavigation(props) {
  const { current_tab, changeTab } = props;
  const classes = useStyles();
  const tabs = Object.values(TAB_VIEWS);

  return (
    <div className={classes.tab_items}>
    {tabs.map(tab => (
      <TabItem
        key={tab}
        tab={tab}
        current_tab={current_tab}
        changeTab={changeTab}
      />
    ))}
  </div>
  );
}
