import React, {useState, useEffect} from 'react';
import TAB_VIEWS from './TAB_VIEWS';
import FeedbackComments from './FeedbackComments/FeedbackComments';
import FeedbackPhotos from './FeedbackPhotos/FeedbackPhotos';
import FeedbackMap from './FeedbackMap/FeedbackMap';
import FeedbackNavigation from './FeedbackNavigation';
import { getFeedbackDetails } from '../../store/processors/feedback';

export default function Content(props) {
    const [current_tab, setCurrentTab] = useState(TAB_VIEWS.MAP);
    const [feedback, setFeedback] = useState({ ...props.feedback })
    
    // Load the comments and media from the server
    useEffect(() => {
        getFeedbackDetails(feedback).then(hydrated_feedback => {
            console.log('Found hydrated feedback', hydrated_feedback);
            updateFeedback(hydrated_feedback);
        })
    }, []);

    const updateFeedback = (new_feedback) => {
        setFeedback({
            ...feedback,
            ...new_feedback,
        })
    }

    return (<>
        {/* <TabItems changeTab={(new_tab) => setCurrentTab(new_tab)} current_tab={current_tab} /> */}
        <FeedbackNavigation changeTab={(new_tab) => setCurrentTab(new_tab)} current_tab={current_tab}/>
        
        {current_tab === TAB_VIEWS.MAP && <FeedbackMap feedback={feedback} /> }  
        {current_tab === TAB_VIEWS.COMMENTS && <FeedbackComments feedback={feedback} updateFeedback={updateFeedback}/> }
        {current_tab === TAB_VIEWS.PHOTOS && <FeedbackPhotos feedback={feedback} updateFeedback={updateFeedback} /> }  
    </>)
}