import React, {
  useState,
  useEffect,
} from "react";

import classes from "./comments.css";
import Comment from "./Comment/comment";

const comments = props => {
  const {comments,media,onDelete, onAddMedia, onUpdateComment, onReloadCommentsMedia} = props;
  const comments_element = props.comments.map(item => {
    const media_element = props.media.find(m => {
      return m.comment === item.id;
    });

    return <Comment key={item.id} comment={item} media= {media_element} onDelete={onDelete} onAddMedia={onAddMedia} onUpdateComment={onUpdateComment} onReloadCommentsMedia={onReloadCommentsMedia}/>;
  });

  return (
    <React.Fragment>
      <div className={classes.CommentPane}>{comments_element}</div>
    </React.Fragment>
  );
};

export default comments;
