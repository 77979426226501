import React, { PureComponent } from "react";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import AddIcon from "@material-ui/icons/AddCircle";
import RemoveIcon from "@material-ui/icons/RemoveCircle";
import Button from "@material-ui/core/Button";
import Fab from "@material-ui/core/Fab";
import Typography from "@material-ui/core/Typography";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Hidden from "@material-ui/core/Hidden";
import Divider from "@material-ui/core/Divider";
import Paper from "@material-ui/core/Paper";

const StyledButton = withStyles((theme)=>({
  root: {
    //  background: 'gray',
    borderRadius: 3,
    border: 0,
    //   color: 'white',
    // backgroundColor:theme.palette.primary,
    height: 32,
    padding: "0 5px",
    boxShadow: "0 1px 3px 1px rgba(64, 64, 64, .3)"
  },
  label: {
    //    textTransform: 'capitalize',
    fontSize: 10
  }
}))(Button);

const IconButton = withStyles((theme) => ({
  root: {
    minWidth: "0",
    padding: "0",
    // color: theme.palette.primary,
  }
}))(Button);

const styles = theme => ({
  paper: {
    padding: "10px",
    width: "100%"
  },
  detailpaper: {
    padding: "10px",
    width: "100%",
    // color: theme.palette.text.primary
  },
  buttonContainer: {
    marginBottom: "5px",
    marginRight: "5px",
  },
  is_example: {
    backgroundColor: '#d5d5d666',
    marginBottom: '.5rem',
  },
  shortForm: {
    marginLeft: "5px",
    marginRight: "5px"
  },
  subHeader: {
    fontSize: "x-small",
    // color: theme.palette.text.primary
  }
});

const to_date_string = dt => {
  let dt_date = new Date(dt);
  let options = { year: "numeric", month: "short" };
  return dt_date.toLocaleString("en-US", options);
};

class Project extends PureComponent {
  render() {
    const { classes } = this.props;
    const {
      id,
      project_number,
      project_name,
      project_location,
      project_summary,
      project_boundary,
      project_manager,
      client_project_manager,
      technical_manager,
      project_facilitator,
      project_startdate,
      project_enddate,
      products,
      selectedProjectId,
      clicked,
      launch,
      is_demo,
    } = this.props;

    let productElements = products
      .map(prd => {
        if (prd.hasOwnProperty('package_name')){
          // return prd.package_name
          return {package_name: prd.package_name, package_code: prd.package_code};
        }
        // Deprecated v 0.2.6
        return {package_name: prd.product_major_title, package_code: prd.package_code};
      })
      // .filter((value, index, self) => self.indexOf(value) === index)
      .filter( (value, index, self) => {
        return index === self.findIndex(t => (
          t.package_name === value.package_name && t.package_code === value.package_code
        ))
      })
      .map(value => {
        let subprods = { title: value.package_name, ids: [], package_code: value.package_code };
        for (let prd of products) {
          if (prd.hasOwnProperty('package_name')){
            if (prd.package_name === value.package_name)
              subprods.ids.push(prd.product_id);
          } else {
            // Deprecated v 0.2.6
            if (prd.product_major_title === value)
              subprods.ids.push(prd.product_id);
          }
        }
        return subprods;
      })
      .map(value => {
        return (
          <Grid
            item
            key={value.ids.join("-")}
            className={classes.buttonContainer}
          >
            <StyledButton
              onClick={() => launch(id, value.ids, value.package_code)}
              size="small"
              color="primary"
              variant="contained"
            >
              {value.title}
            </StyledButton>
          </Grid>
        )
      });
    const expandedItem = (
      <Grid item xs={12}>
        <Paper className={classes.detailpaper}>
          <Grid item xs={12} className={classes.shortForm}>
            <Grid container spacing={1}>
              <Hidden smUp>
                <Grid item xs={12}>
                  <Grid container>{productElements}</Grid>
                </Grid>
              </Hidden>
              <Grid item xs={12}>
                <b>Project Summary</b>: {project_summary}
              </Grid>
              <Grid item xs={6}>
                <div>
                  <b>Client Manager</b>: {client_project_manager}
                </div>
                <div>
                  <b>Project Manager</b>: {project_manager}
                </div>
              </Grid>
              <Grid item xs={6}>
                <div>
                  <b>Technical Manager</b>: {technical_manager}
                </div>
                <div>
                  <b>Project Facilitator</b>: {project_facilitator}
                </div>
              </Grid>
              <Grid item xs={12}>
                <b>Project Identifier</b>: {project_number}
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    );

    return (
      <React.Fragment>
        <Paper className={is_demo ? classes.is_example : classes.paper}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Grid container alignItems="center">
                <Grid item xs={1} sm={1} md={1}>
                  <IconButton
                    size="small"
                    color="primary"
                    onClick={() => clicked(id)}
                  >
                    {selectedProjectId === id ? <RemoveIcon /> : <AddIcon />}
                  </IconButton>
                </Grid>
                <Grid item xs={11} sm={4} md={5}>
                  <div>{project_name}</div>
                  <div className={classes.subHeader}>
                    <Grid container>
                      <Grid item xs={6}>
                        {project_location}
                      </Grid>
                      <Grid item xs={6} style={{ textAlign: "right" }}>
                        <Hidden smUp>
                          {to_date_string(project_startdate)} -{" "}
                          {to_date_string(project_enddate)}
                        </Hidden>
                      </Grid>
                    </Grid>
                  </div>
                </Grid>
                <Hidden xsDown>
                  <Grid item sm={4} md={4}>
                    <Grid container>{productElements}</Grid>
                  </Grid>
                </Hidden>
                <Hidden xsDown>
                  <Grid
                    item
                    sm={3}
                    md={2}
                    style={{ textAlign: "right", fontSize: "small" }}
                    className={classes.detailpaper}
                  >
                    {to_date_string(project_startdate)} -{" "}
                    {to_date_string(project_enddate)}
                  </Grid>
                </Hidden>
              </Grid>
            </Grid>

            {selectedProjectId === id && expandedItem}
          </Grid>
        </Paper>
       {!is_demo &&  <Divider />}
      </React.Fragment>
    );
  }
}

Project.propTypes = {
  classes: PropTypes.object.isRequired
  // other props
};
export default withStyles(styles)(Project);
