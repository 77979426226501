import React, {useState} from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core';
import EXIF from 'exif-js';
import moment from 'moment'
import Button from '@material-ui/core/Button';


import ImagePreview from '../../ImagePreview/ImagePreview';
import Input from '../../UI/Input/Input';
import * as settings from '../../../constants/settings';
import { createFeedbackMedia } from '../../../store/processors/feedback';
import { createFeedbackPhoto } from '../../../store/actions/alerts';

import SioLogger from "../../../modules/Logger/logger";

const logger = SioLogger('processors/index.js')

const useStyles = makeStyles( theme => ({
    media_pane: {
        width: '100%',
        textAlign: 'center',
        padding: '1rem',
    }, 
    spinnerdiv: {
        position: 'static',
    },
    image_container: {
        position: 'relative',
        width: '100%',
        height: '80%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgba(43,57,144,0.25)',
    },
}))

const FeedbackPhotoForm = props => {

    const classes = useStyles();
    const { feedback } = props;
    const { fbguid } = feedback.properties;
    
    const [file, setFile] = useState('');
    const [file_is_valid, setFileIsValid] = useState(true);
    const [comment, setComment] = useState('');
    const [comment_is_valid, setCommentIsValid] = useState(true);
    const [uploading, setUploading] = useState(false);
    

    const checkValidity = (value, rules) => {

    }

    const getEXIFCaptureDate = (input_file) => {

        let str = EXIF.getTag(input_file, "DateTimeOriginal");  
        
        if (!str) {
          // Apple device does not have this tag set 
          // when picture loaded from camera roll
          // compared to using a photo in the gallery.
          // Andoid does not have this problem
          str = moment().format("YYYY:MM:DD HH:mm:ss")
        }
    
        str = str.split(" ")
        const dateStr = str[0].replace(/:/g, "-");
        const properDateStr = dateStr + " " + str[1];
      
        return moment(properDateStr).toISOString();
      }

    const getEXIFData = (input_file) => {
        console.log('getEXIFData called.');

        if (input_file.complete) {
            console.log('image was complete')
            input_file.capture_date = getEXIFCaptureDate(input_file)
            setFile(input_file);
            return;
        }

        EXIF.getData(input_file, function() {
          console.log('Getting capture Date ');
          input_file.captureDate = getEXIFCaptureDate(input_file);
          console.log('Found capture date', input_file.captureDate);
          setFile(input_file)
        })
      }

    const fileChangedHandler = (event) => {
        let input_file = event.target.files[0];
        console.log('Setting Input file', input_file);

        if (!input_file) return console.log('Could not find input file');

        setFile(input_file);
    }

    const onSubmitHandler = async (e) => {
        e.preventDefault();

        const MAX_FILE_SIZE_MB = 10; // Set the maximum file size (e.g., 10 MB)
        const MAX_FILE_SIZE_BYTES = MAX_FILE_SIZE_MB * 1024 * 1024;

        if (file && file.size > MAX_FILE_SIZE_BYTES) {
            logger.warn(`File size too large. Maximum allowed size is ${MAX_FILE_SIZE_MB} MB.`);
            alert(`File size too large. Maximum allowed size is ${MAX_FILE_SIZE_MB} MB.`);
            return; // Stop the form submission if the file is too large
        }
        const stamp = new Date();
        const payload = {
          data: {
            id: stamp.getTime(),
            fbguid: feedback.properties.guid,
            capture_date: new Date().toISOString(),
            file,
            comment,
            unsynced: feedback.unsynced || false,
          }
        }

        console.log('Submitting Payload', payload);
        try {
            const response = await props.createFeedbackPhoto(payload)
            console.log('photo payload response', response);
        } catch (error) {
            console.log('Error submitting feedback media', error);
        }
        props.cancel();
        

        
        /**
         * PAYLOAD IS IN FORM DATA
         * Payload: {
         *  fbguid: 'c75b53c3-25b0-4d53-855f-e8911de4a8da',
         * capture_date: new Date.toISOString(),
         * comment: comment,
         * file: file,
         * }
         */

        /**
         * Response: {
         * id: 2 // stamp?
         * observation: null, 
         * fbguid: 'c75b53c3-25b0-4d53-855f-e8911de4a8da'
         * comment: 5 // stamp?
         * comment_text: 'Comment',
         * memberid: '2505e465-69a7-4df1-af6f-55a83951294b'
         * username: 'Coty Crosby', // from token?
         * capture_date: new Date.toISOString(),
         * filename: "satelytics-money-hash.png",
         * fileSize: 585994,
         * thumbnail: 'tb-satelytics-money-hash.png',
         * thumbnail_size: 0,
         * download_url: '/assets/scanprocessing.jpeg',
         * thumbnail_url: '/assets/scanprocessing.jpeg',
         * status: 0,
         * created: new Date().toISOString(),
         * modified: new Date().toISOString(),
         * }
         */
    }

    const imageChangedHandler = (imageChanged) => {}

    return <div classes={classes.media_pane}>
        {/* Uploading Animation */}
        {uploading && (
            <div className={classes.spinnerdiv}>
                Loading photo....
                <Spinner />
            </div>
        )}

        {!uploading && (
            <>
                <div className={classes.image_container}>
                    {file && (
                        <ImagePreview file={file} dataURL={imageChangedHandler}/>
                    )}
                </div>
                
                <form onSubmit={onSubmitHandler}>
                    <Input 
                        key='mediaFile'
                        elementType='file'
                        muiConfig={{
                            label: '', 
                            fullWidth: true
                        }}
                        elementConfig={{
                            accept: 'image/*',
                            placeholder: '',
                            type: 'file'
                        }}
                        // value={file}
                        invalid={file_is_valid}
                        shouldValidate={{required: true}}
                        touched={false}
                        changed={fileChangedHandler}
                    />

                    <Input 
                        key='comment'
                        elementType='textarea'
                        muiConfig={{
                            label: 'Comment:', 
                            fullWidth: true,
                            multiline: true,
                            placeholder: 'Comment...',
                            minRows: 5,
                        }}
                        elementConfig={{}}
                        value={comment}
                        invalid={comment_is_valid}
                        shouldValidate={{max_length: 10240}}
                        touched={false}
                        changed={event => setComment(event.target.value)}
                    />

                    <div>
                        <Button
                            disabled = {!file}
                            variant="contained"
                            type="submit"
                            color="primary">
                            Save
                        </Button>
                        
                        <Button 
                            variant="contained" 
                            color="default" 
                            onClick={props.cancel}>
                            Cancel
                        </Button>
                    </div>


                </form>
            </>
        )}
    </div>
}

const mapStateToProps = (state) => ({});
const mapDispatchToProps = (dispatch) => ({
    createFeedbackPhoto: (media) => dispatch(createFeedbackPhoto(media)),
})

export default connect(mapStateToProps, mapDispatchToProps)(FeedbackPhotoForm);