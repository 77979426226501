import React, {
  Component
} from "react";
import {
  connect
} from "react-redux";
import PropTypes from "prop-types";
import Project from "../../components/Project/Project";
import axios from "../../api/axios-api";
import Spinner from "../../components/UI/Spinner/Spinner";
import * as Actions from "../../store/actions/index";
import Paper from "@material-ui/core/Paper";
import {
  withStyles
} from "@material-ui/core/styles";
import {
  withRouter
} from "react-router-dom";
import moment from 'moment';
import {
  getDateRangeSelection,
  getMapZoomCenter,
  getShowExampleProjectsSetting
} from "../../store/localStorage/index";
import {
  getSelectedProjectsZoomCenter,
  initializeMap
} from "../../modules/Map/map";
import SioLogger from "../../modules/Logger/logger";
import purgeOldLogs from "../../modules/Logger/purgeOldLogs";

const logger = SioLogger('containers/homeContainer.js')

const styles = theme => ({
  root: {
    width: "100%",
    // marginTop: theme.spacing.unit * 3,
    // overflowX: "auto",
    // height: "100%"
    // position: "fixed"
  },
  map: {
    top: "0",
    // marginTop: "3.5rem",
    width: "100%",
    height: "calc(100% - 6rem)",
    // position: "absolute",
    zIndex: "-1"
  },
  mapContainer: {
    display: "block",
    visibility: "hidden"
  }
});

class homeContainer extends Component {
  state = {
    selectedProjectId: null,
    map: null
  };

  componentDidMount() {
    const {
      projects,
      productsLength,
      onFetchProjects,
      onFetchLookups,
      onFetchBaseLayers,
      onFetchRefLayers
    } = this.props;
    if (!projects || projects.length === 0) {
      onFetchProjects();
    }
    if (productsLength === 0) {
      onFetchLookups();
      onFetchBaseLayers();
      onFetchRefLayers();
      purgeOldLogs();
    }

    if (this.props.alertsLength === 0) {
      this.props.onFetchAlerts();
      
      if (this.props.online) {
        this.props.onFetchFeedbackOnline();
      } else {
        this.props.onFetchFeedbackFromCache();
      }

    }
  }

  componentDidUpdate() {
    if ((!this.state.map) && (this.props.alertsLength > 0)) {
      this.setState({
        map: initializeMap("temp", getMapZoomCenter())
      })
    }
  }

  projectSelectdHandler = id =>
    this.setState(prev => {
      if (prev && prev.selectedProjectId === id)
        return {
          selectedProjectId: null
        };
      else return {
        selectedProjectId: id
      };
    });

  launch = (projectId, productIds, package_code) => {
    const {
      checkProjects,
      checkProducts,
      updateTimeFilter,
      history,
      onZoomEnd
    } = this.props;

    checkProducts(productIds);
    checkProjects([projectId]);

    logger.info('Opening project ' + projectId, {project: projectId, products: productIds, package_code})

    let DRS = getDateRangeSelection();
    const _start = moment(DRS.startDate)
      .subtract(1, 'week')
      .valueOf();
    const _end = moment(DRS.endDate)
      .add(1, 'week')
      .valueOf();

    this.props.setActivePackage(package_code);

    updateTimeFilter({
      start: _start,
      end: _end
    });

    let map;
    if (this.props.map) {
      map = this.props.map
    } else {
      map = this.state.map
    }

    const initialZoomCenter = getSelectedProjectsZoomCenter(map,
      this
      .props
      .projects, [projectId]);

    onZoomEnd(initialZoomCenter);
    history.push("/alerts");
  };

  render() {
    let clientProjectElements = null;
    let exampleProjectElements
    const {
      classes,
      loading,
      projects
    } = this.props;
    const {
      selectedProjectId
    } = this.state;
    const showExampleProjects = getShowExampleProjectsSetting();

    if (!loading && projects.length > 0) {
      // See the sio-viewer project tile sorting function
      // update to use locale string compare
      projects.sort((a, b) => {
        let a_name = a.project_name.toUpperCase();
        let b_name = b.project_name.toUpperCase();
        if (a_name > b_name) {
          return 1;
        }
        if (a_name < b_name) {
          return -1;
        }
        return 0;
      });
      clientProjectElements = projects.filter(project => !project.is_demo).map(project => {
        return (
          <Project key={project.id} selectedProjectId={selectedProjectId} launch={this.launch} {...project} clicked={this.projectSelectdHandler} />

        );
      });

      exampleProjectElements = projects.filter(project => project.is_demo).map(project => {
        return (
          <Project key={project.id} selectedProjectId={selectedProjectId} launch={this.launch} {...project} clicked={this.projectSelectdHandler} />
        );
      });



    }

    if (!clientProjectElements || !exampleProjectElements) return <Spinner />;
    return (
      <React.Fragment>
        <div className={classes.mapContainer}>
          <div className={classes.map} id={"temp"} />
        </div>
        <Paper className={classes.root}>{clientProjectElements}</Paper>
        {showExampleProjects && <>
          <h3 style={{textAlign: 'center'}}>Example Projects</h3>
          <Paper className={classes.root}>{exampleProjectElements}</Paper>
        </>}
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  online: state.app.online,
  projects: state.projects.projects,
  loading: state.projects.loading,
  error: state.projects.error,
  map: state.map.mapMain,
  alertsLength: state.alerts.alerts.length,
  productsLength: state.lookups.products.length
});

const mapDisptachToProps = dispatch => ({
  onFetchProjects: () => dispatch(Actions.refreshProjects()),
  checkProjects: selected => dispatch(Actions.checkProjects(selected)),
  checkProducts: selected => dispatch(Actions.checkProducts(selected)),
  updateTimeFilter: timeFilter => dispatch(Actions.updateTimeFilter(
    timeFilter)),
  onZoomEnd: zoomcenter => dispatch(Actions.updateMapZoomCenter(
    zoomcenter)),
  onFetchLookups: () => dispatch(Actions.refreshLookups()),
  onFetchBaseLayers: () => dispatch(Actions.fetchBaseLayers()),
  onFetchRefLayers: () => dispatch(Actions.fetchReferenceLayers()),
  onFetchAlerts: () => dispatch(Actions.fetchAlertsFromCache()),
  onFetchFeedbackFromCache: () => dispatch(Actions.fetchFeedbackFromCache()),
  setActivePackage: (active_package) => dispatch(Actions.setActivePackage(active_package)),
  onFetchFeedbackOnline: () => dispatch(Actions.initializeFeedback()),
});

homeContainer.propTypes = {
  classes: PropTypes.object.isRequired
};

export default connect(
  mapStateToProps,
  mapDisptachToProps
)(withRouter(withStyles(styles)(homeContainer)));
