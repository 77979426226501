import * as actionTypes from "./actionTypes";
import axios_api from "../../api/axios-api";
import db from "../db";
import store from "../index";
import { processItem } from "../processors/index";
import * as modelTypes from "../modelTypes";
import * as modelActions from "../modelActions";
import {
  getCommentsFromCache
} from "../processors/comments-media";
import {
  getCommentsFromServer
} from "../processors/shared-functions";
import SioLogger from "../../modules/Logger/logger";

const logger = SioLogger('src/store/actions/comments-media.js');

const fetchCommentsSuccess = (comments) => {
  return {
    type: actionTypes.FETCH_COMMENTS_SUCCESS,
    data: comments
  };
};

export const addCommentSuccess = (comment) => {
  return {
    type: actionTypes.ADD_COMMENT_SUCCESS,
    data: comment
  };
};

export const saveCommentSuccess = (comment) => {
  return {
    type: actionTypes.SAVE_COMMENT_SUCCESS,
    data: comment
  };
};

export const removeCommentSuccess = (item) => {
  return {
    type: actionTypes.REMOVE_COMMENT_SUCCESS,
    data: item
  };
};

const fetchMediaSuccess = (media) => {
  return {
    type: actionTypes.FETCH_MEDIA_SUCCESS,
    data: media
  };
};

const manageMediaStart = () => {
  return {
    type: actionTypes.MANAGE_MEDIA_START
  };
};

export const addMediaSuccess = (media) => {
  return {
    type: actionTypes.ADD_MEDIA_SUCCESS,
    data: media
  };
};

export const saveMediaSuccess = (media) => {
  return {
    type: actionTypes.SAVE_MEDIA_SUCCESS,
    data: media
  };
};

export const removeMediaSuccess = (media) => {
  return {
    type: actionTypes.REMOVE_MEDIA_SUCCESS,
    data: media
  };
};

// Refactor to place into CRUD file
export const fetchComments = (obsid) => {
  return (dispatch) => {
    if (store.getState().app.online) {
      logger.info("Fetching comments from server for record id " + obsid);
      getCommentsFromServer(obsid)
        .then((response) => {
          if (response instanceof Array && response.length > 0) {
            dispatch(fetchCommentsSuccess(response));
          }
        })
        .catch((error) => {
          logger.error('Failed to retrieve comments for record id: ' + obsid, error);
        });
    }
    return getCommentsFromCache(obsid).then((response) => {
      if (response.length > 0) {
        dispatch(fetchCommentsSuccess(response));
      }
      return response;
    });
  };
};

export const addComment = (item) => {
  return (dispatch) => {
    return processItem(modelActions.CREATE, modelTypes.COMMENT, item)
      .then((response) => {
        logger.info("Created comment ")
        dispatch(addCommentSuccess(response));
        return response;
      })
      .catch((error) => {
        logger.error('Failed to create comment: ' + error.message, {error, item});
        return { error: error.message };
      });
  };
};

export const updateComment = (item) => {
  return (dispatch) => {
    return processItem(modelActions.UPDATE, modelTypes.COMMENT, item)
      .then((response) => {
        dispatch(saveCommentSuccess(response));
        return response;
      })
      .catch((error) => {
        logger.error('Failed to update comment: ' + error.message, {error, item});
        return { error: error.message };
      });
  };
};

export const removeComment = (item) => {
  return (dispatch) => {
    return processItem(modelActions.DELETE, modelTypes.COMMENT, item)
      .then((response) => {
        dispatch(removeCommentSuccess(item));
        return response;
      })
      .catch((error) => {
        logger.error('Failed to remove comment: ' + error.message, {error, item});
        return { error: error.message };
      });
  };
};

export const addMedia = (item) => {
  const _filename = item.get("file").name
  return (dispatch) => {
    dispatch(manageMediaStart());
    logger.info("Adding photo " + _filename)
    return processItem(modelActions.CREATE, modelTypes.MEDIA, item)
      .then((response) => {
        logger.info("Added photo " + _filename)
        dispatch(addMediaSuccess(response));
        return response;
      })
      .catch((error) => {
        logger.error('Failed to add photo: ' + error.message, {error, item});
        return { error: error.message };
      });
  };
};

// Refactor to place into CRUD file
export const fetchMedia = (obsid) => {
  const startTimer = new Date();
  return (dispatch) => {
    // Cache then Network strategy
    // Server Worker will pull from cache when no network is available
    // Assuming photo was previsouly viewed or added via batch load event
    if (store.getState().app.online) {
      logger.info("Fetching photo from server for record ID " + obsid);
      // Fetch any updates from the server
      axios_api.get("/v2/observations/" + obsid + "/media").then((response) => {
        const stopTimer = new Date();
        logger.info(
          "Fetching " +
          response.data.length +
          " photo records from server took " +
          (stopTimer.getTime() - startTimer.getTime()) / 1000 +
          " seconds."
        );

        // Let Redux know about the updates from the server
        dispatch(fetchMediaSuccess(response.data));

        // Be sure to replace the local cache of records
        return db
          .table("media")
          .where("observation")
          .equals(obsid)
          .delete()
          .then((deleteCount) => {
            logger.info("Removing " + deleteCount + " records from media table in local DB.");
            logger.info("Adding " + response.data.length + " records to the media table in the local DB.");
            db.table("media").bulkAdd(response.data);
            return response.data;
          });
      });
    } else {
      return db
        .table("media")
        .where("observation")
        .equals(obsid)
        .reverse()
        .toArray()
        .then((response) => {
          const stopTimer = new Date();
          logger.info(
            "Retrieving " + response.length + " photo records from cache took " +
            (stopTimer.getTime() - startTimer.getTime()) / 1000 +
            " seconds."
          );
          if (response.length > 0) {
            dispatch(fetchMediaSuccess(response));
          }
          
          return response;
        });
    }
  };
};

export const updateMedia = (item) => {
  return (dispatch) => {
    dispatch(manageMediaStart());
    return processItem(modelActions.UPDATE, modelTypes.MEDIA, item)
      .then((response) => {
        dispatch(saveMediaSuccess(response));
        return response;
      })
      .catch((error) => {
        logger.error('Failed to update photo: ' + error.message, {error, item});
        return { error: error.message };
      });
  };
};

export const removeMedia = (item) => {
  const _filename = item.filename
  return (dispatch) => {
    dispatch(manageMediaStart());
    logger.info("Removing photo " + _filename)
    return processItem(modelActions.DELETE, modelTypes.MEDIA, item)
      .then((response) => {
        logger.warn("Removed photo " + _filename)
        dispatch(removeMediaSuccess(item));
        return response;
      })
      .catch((error) => {
        logger.error('Failed to remove photo: ' + error.message, {error, item});
        return { error: error.message };
      });
  };
};

export const addFeedback = (form_values) => {
  return (dispatch) => {
    return processItem(modelActions.CREATE, modelTypes.FEEDBACK, form_values)
      .then(response => {
        logger.info('Feedback submitted successfully', form_values)
      }).catch((error => {
        
      }))
  }
}
