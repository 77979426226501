import React from 'react';
import { connect } from "react-redux"
import { showFeedbackForm } from '../../store/actions';
import { FEEDBACK_FORM } from '../../constants/contentViews';
import Cancel from '@material-ui/icons/Cancel';
import Feedback from '@material-ui/icons/Feedback';
import IconButton from "@material-ui/core/IconButton";

/**
 * Handles the controls of the feedback button in the actionbar items component
 * When the button is clicked
 *  - Adds an event listener to the map to capture the latlng of the click event
 *  - Converts itself to a cancel button
 * 
 * When the map click event is triggered
 * - Stores the latlng
 * - Changes the currentView State to "redirect" the user to the feedback form to create feedback.
 */
const FeedbackActionbarItem = props => {
    const [is_gathering_feedback_location, setIsGatheringFeedbackLocation] = React.useState(false);

    /**
     * The map click event handler used to get the latlng object for the feedback form.
     * - Reverts the button back from a cancel state to a start state.
     * @param {event} e
     */
    const mapClickEvent = (e) => {
        setIsGatheringFeedbackLocation(false);
        console.log('Map Click Event', e);
        props.showFeedbackForm(e.latlng);
        props.setView(FEEDBACK_FORM)
    }

    /** Removes the map event listener and resets the button */
    const cancelFeedbackAttempt = () => {
        setIsGatheringFeedbackLocation(false);
        props.mapMain.off('click', mapClickEvent);
        props.toggleFeedbackMessage('No longer gathering feedback.')
    }


    /** Applies the map event listener and changes the button to a cancel button */
    const startFeedbackAttempt = () => {
        console.log('startFeedbackAttempt');
        setIsGatheringFeedbackLocation(true);
        props.mapMain.on('click', mapClickEvent);
        props.toggleFeedbackMessage('Feedback mode enabled: Please click on the map to select the point for feedback.');
    }


    /** This item is represented by either a Feedback button or a Cancel Button*/
    return is_gathering_feedback_location
        ? (
            <IconButton onClick={cancelFeedbackAttempt} color={"inherit"}>
                <Cancel />  
            </IconButton>
        ) : (
            <IconButton onClick={startFeedbackAttempt} color={"inherit"}>
                <Feedback />
            </IconButton>
        )

}

const mapStateToProps = state => ({
    mapMain: state.map.mapMain,
})

const mapDispatchToProps = dispatch => {
    return {
        showFeedbackForm: (latlng) => dispatch(showFeedbackForm(latlng)),
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(FeedbackActionbarItem)