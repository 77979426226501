import React from "react";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  image_container: {
    margin: '2px',
    padding: '2px',
    height: '65px',
  }, 
  image: {
    width: '65px',
    height: '65px',
  }
}))

export default function FeedbackPhoto(props) {
  const {media, clicked} = props
  const classes = useStyles();

  return (
    <div
      className={classes.image_container}
      onClick={() => clicked(media.id)}
    >
      <img src={media.thumbnail_url} className={classes.image}/>
    </div>
  );
};  