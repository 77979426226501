import React, { useState } from "react";
import { connect } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { createFeedback } from "../../store/actions/alerts";
import {v4} from 'uuid';

const useStyles = makeStyles(theme => ({
  root: {
    "& .MuiTextField-root": {
      width: "100%"
    },
    "& .MuiFormControl-root": {
      width: "100%"
    }
  },
  formControl: {
    minWidth: 120,
  },
  feedback_form: {
    padding: theme.spacing(1),
    margin: theme.spacing(1),
  },
  comments: {
    border: '1px solid #c9c9d3',
    padding: theme.spacing(1),
  },
  subtext: {
    color: '#959599',
  },
  media_container: {
    display: 'flex',
    alignItems: 'center',
    gap: '1rem',
  },
  upload_button: {
    fontSize: '0.8rem',
  }
}));

const FeedbackForm = props => {
  const classes = useStyles();
  const [form_values, setFormValues] = useState({
    comment: "",
    media: null,
    submitter: props.user,
    package_code: props.active_package,
    date_submitted: new Date().toISOString(),
    location: props.feedback_latlng,
  });


  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate that the user has at least submitted 10 characters of feedback.
    if (form_values.comment.length < 10) {
      return alert('Please provide a comment of at least 10 characters');
    }

    const today = new Date();
    const feedback_guid = v4();

    const payload = {
      feedback: {
        guid: feedback_guid,
        submitter: form_values.submitter,
        package_code: form_values.package_code,
        latitude: form_values.location.lat.toFixed(5),
        longitude: form_values.location.lng.toFixed(5),
      },
      comments: [],
      media: [],
    }

    payload.comments.push({
      fbguid: feedback_guid,
      comment: form_values.comment,
      created: today.toISOString(),
      modified: today.toISOString(),
    });

    if (form_values.media) {
      payload.media.push({
        file: form_values.media,
        fbguid: feedback_guid,
        created: today.toISOString(),
        modified: today.toISOString(),
      });
    }

    console.log('Submitting feedback', payload)

    try {
      const feedback_response = await props.createFeedback(payload)
      console.log('Feedback Response', feedback_response);
      closeFeedbackForm();
    } catch (error) {
      console.log('Error submitting feedback', error);
    }    
  };

  const handleChange = e => {
    const { name, value } = e.target;
    setFormValues({...form_values, [name]: value, })
  };

  const handleMediaChange = e => {
    const {name} = e.target;
    setFormValues({...form_values, [name]: e.target.files[0]})
  }

  const closeFeedbackForm = () => {
    props.actionViewHandler('MAIN_VIEW_MAP')
  }
 
  return (
    <div 
      className={classes.feedback_form}
    >
      <form
        className={classes.root}
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit}
      >

        {/* Date Observed Field */}
        <Grid 
          container 
          spacing={3}
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={12} container justifyContent="center">
            <Typography variant="h6" gutterBottom>
              Submit Feedback
            </Typography>
          </Grid>

          {/* Comments Text Area */}
          <Grid item xs={12} md={9} lg={6}>
            <TextField
              className={classes.comments}
              id="comment"
              name="comment"
              label="Comments"
              multiline
              minRows={4}
              value={form_values.comment}
              onChange={handleChange}
              InputProps={{
                disableUnderline: true, // Disables the underline
              }}
              InputLabelProps={{
                style: { paddingLeft: '8px' }, // Add padding to the label
              }}
              fullWidth
            />
          </Grid>

          {/* Media Upload */}
          <Grid 
            item xs={12}
            container 
            justifyContent="center"
          >
            <div className={classes.media_container} style={{ textAlign: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Typography variant='body1' className={classes.subtext}>
                    {form_values.media ? form_values.media.name : 'No file selected (Optional)'}
                </Typography>
                <Button variant="contained" component="label" className={classes.button} color='secondary'>
                    Select Image
                    <input hidden type='file' name='media' onChange={handleMediaChange}/>
                </Button>
            </div>
          </Grid>

          {/* Submit Button */}
          <Grid 
            item xs={12}
            container 
            justifyContent="center"
          >
            <Button
              type="submit"
              variant="contained"
              color="primary"
              className={classes.button}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
        {/* Container Grid */}
      </form>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    user: state.auth.userName,
    feedback_latlng: state.map.feedback_latlng,
    active_package: state.projects.active_package,
  };
};

const mapDispatchToProps = dispatch => ({
  createFeedback: (feedback) => dispatch(createFeedback(feedback)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FeedbackForm);
