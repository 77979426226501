import React, {useState} from 'react';
import { makeStyles } from '@material-ui/core';
import Header from '../../components/FeedbackView/FeedbackHeader';
import Content from '../../components/FeedbackView/FeedbackContent';
import ActionbarItems from '../../components/Navigation/Actionbar/ActionbarItems/ActionbarItems';
import Modal from '../../components/UI/Modal/Modal';
import FeedbackCommentForm from '../../components/FeedbackView/FeedbackComments/FeedbackCommentForm';
import FeedbackPhotoForm from '../../components/FeedbackView/FeedbackPhotos/FeedbackPhotoForm';

const useStyles = makeStyles(theme => ({
    feedback_pane: {
        width: "100%",
        height: "100%",
        backgroundColor: "#FFF",
    }  
}));

export default function FeedbackContainer(props) {
    const {feedback, closeFeedbackContainer} = props;
    const classes = useStyles();
    const [comment_form_is_opened, setCommmentFormIsOpened] = useState(false);
    const [media_form_is_opened, setMediaFormIsOpened] = useState(false);
    const modal_is_visible = comment_form_is_opened || media_form_is_opened;

    const addCommentHandler = () => {
        setCommmentFormIsOpened(true);
    }
    const addMediaHandler = () => {
        setMediaFormIsOpened(true)
    }

    const cancelModalHandler = () => {
        setCommmentFormIsOpened(false);
        setMediaFormIsOpened(false);
    }

    return <>
        <div className={classes.feedback_pane}>
            <Header feedback={feedback} closeFeedbackContainer={closeFeedbackContainer}/>
            <Content feedback={feedback} closeFeedbackContainer={closeFeedbackContainer}/>

            <Modal show={modal_is_visible} modalClosed={cancelModalHandler}>
                {comment_form_is_opened && (
                    <FeedbackCommentForm feedback={feedback} cancel={cancelModalHandler}/>
                )}

                {media_form_is_opened && (
                    <FeedbackPhotoForm feedback={feedback} cancel={cancelModalHandler}/>
                )}
            </Modal>

            <ActionbarItems
                currentView='FEEDBACK_DETAILS'
                closeDetails={closeFeedbackContainer}
                addComment={addCommentHandler}
                addMedia={addMediaHandler}
                layerControl={() => {}}
                locateMe={() => {}}
                watching={false}
                locationErrorMessage={''}
                locationFound={false}
                locationError={false}
            />
        </div>
    </>
}
