import ActionbarItems from "../../components/Navigation/Actionbar/ActionbarItems/ActionbarItems";
import React, { Component } from "react";
import ObsDetail from "../../containers/ObsDetail/obsDetail";
import FeedbackContainer from "../feedbackContainer/FeedbackContainer";
import { connect } from "react-redux";
import Grid from "@material-ui/core/Grid";
import Alerts from "../../components/Alerts/Alert/MUIAlertMobile";
import Map from "../../components/Map/MUIMap";
import FeedbackForm from "../../components/Feedback/FeedbackForm";

import * as Actions from "../../store/actions/index";

import { withStyles } from "@material-ui/core/styles";
import moment from "moment";
import TextField from "@material-ui/core/TextField";

import { MAIN_VIEW_MAP, MAIN_VIEW_LIST, FEEDBACK_FORM, FEEDBACK_DETAILS } from "../../constants/contentViews";
import BatchDownload from "../../components/Dialogs/BatchDownload/BatchDownload";
import Modal from "../../components/UI/Modal/Modal";
import L from "leaflet";
import SioLogger from "../../modules/Logger/logger";
import getFilteredAlerts from "./getFilteredAlerts";
import CurrentStatusContext from "./CurrentStatusContext";

const logger = SioLogger("containers/alertContainer.js");


const styles = theme => ({
  container: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    width: "100%"
  },
  root: {
    flexGrow: 1
  },
  filter: {
    height: "3.0rem"
  },
  TextField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  RecordCount: {
    textAlign: "right",
    paddingBottom: theme.spacing(1),
    paddingRight: theme.spacing(1)
  }
});

// Look to integrate react-virtualized (https://github.com/bvaughn/react-virtualized)
// Michael Jackson Rendering with React (https://www.youtube.com/watch?v=7S8v8jfLb1Q&feature=youtu.be&t=26m2s)
// or this (https://medium.com/vena-engineering/optimizing-react-rendering-61a10e741edb)
// https://www.robinwieruch.de/react-advanced-list-component/
// https://medium.com/pulsar/modern-enterprise-ui-design-part-1-tables-ad8ee1b9feb

class alertContainer extends Component {
  componentDidMount() {
    const {
      observations,
      products,
      status,
      onFetchObservations,
      onFetchSupportData
    } = this.props;

    if (observations.length === 0) {
      logger.info("Need to get the observation data...");
      onFetchObservations();
      this.props.onFetchFeedback();
    }
    if (products.length === 0 || status.length === 0) {
      logger.info("Need to get the supporting data...");
      onFetchSupportData();
    }
  }

  state = {
    currentView: this.props.currentView || MAIN_VIEW_MAP,
    selectedObservation: { id: 0 },
    filteredBookmarks: null,
    filteredObservations: this.props.observations,
    layerControlDialogVisible: false,
    filter: "",
    initialized: false,
    watching: false,
    locationFound: false,
    locationError: false,
    locationErrorMessage: "",
    current_status: this.props.status.length > 0 ? this.props.status[0].code : '',
  };

  

  componentDidUpdate() {
    // Handle a page refresh event
    if (
      this.props.products.length > 0 &&
      this.props.status.length > 0 &&
      !this.state.initialized
    ) {
      this.setState({
        filteredObservations: this.props.observations,
        initialized: true
      });
    }
  }

  componentWillUnmount() {
    if (this.props.map) {
      this.props.map.stopLocate();
    }
  }

  actionViewHandler = view => {
    this.setState({
      currentView: view
    });
    this.props.onCurrentView(view);
  };

  observationSelectionHandler = id => {
    const { observations } = this.props;

    const selectedObservation = observations.filter(obs => obs.id === id)[0];

    this.setSceneInfo(selectedObservation);
  
    this.props.onSelectObservation(selectedObservation);

    this.setState({
      filteredBookmarks: this.filterBookmarks(selectedObservation),
      detailsVisible: true,
      selectedObservation: selectedObservation,
      current_status: selectedObservation.properties.status_code,
    });
  };

  feedbackSelectionHandler = (id) => {
    const selected_feedback = this.props.feedback.filter(fb => fb.id === id)[0];

    this.setState({
      feedback_visible: true,
      selected_feedback: selected_feedback,
    })
  }

  closeFeedbackContainer = () => {
    this.setState({
      feedback_visible: false,
      selected_feedback: null,
    })
  }

  setSceneInfo = observation => {
    observation.scene_based = this.isSceneBased(observation);
    if (observation.scene_based){
      observation.scene_boundary = this.getProjectBounds(observation.properties.project_id)
    }
  };

  filterBookmarks(observation) {
    // Add project defined bookmarks
    const filteredBookmarks =  this.props.bookmarks.filter(item => {
      if (item.hasOwnProperty("projects")){
        return item.projects.includes(observation.properties.project_id)
      } else {
        return false;
      }
    });

    // Add generic bookmarks
    this.props.bookmarks.forEach(element => {
      if (element.hasOwnProperty("projects") && element.projects.length == 0) {
        filteredBookmarks.push(element);
      }
    });

    // Add Default bookmark
    filteredBookmarks.unshift(this.getDefaultBookmark(observation));

    return filteredBookmarks;
  }

  getDefaultBookmark = observation => {
    let _default = {
      id: "0",
      name: "Reset Map",
      boundingbox: null,
      projects: []
    };
    
    if (this.isSceneBased(observation)){
      _default.boundingbox = this.getProjectBounds(observation.properties.project_id)
    } 

    return _default;
  };

  isSceneBased = observation => {
    const _product = this.props.products.find(item => {
      return item.id === observation.properties.product_id;
    })
    
    if (_product){
      return _product.scene_based
    } else {
      return false;
    }
  };

  getProjectBounds = project_id => {
    const _prj = this.props.projects.find(item => {
      return item.id == project_id;
    });
    if (_prj) {
      return L.geoJSON(_prj.project_boundary).getBounds();
    } else {
      return [
        [0, 0],
        [0, 0]
      ];
    }
  };

  handleChangeStatus = (observation_id, status_code) => {
    // Finding the observation and status
    const record_to_update = this.props.observations.find(obs => obs.id === observation_id);
    if (!record_to_update) {
      return console.warn("Unable to find observation with id: ", observation_id);
    }

    const status_record = this.props.status.find(item => item.code === status_code);
    if (!status_record) {
      return console.warn('Unable to find status record ', status_code);
    }

    // modifying the property of the record to have the new status value
    record_to_update.properties.status_code = status_record.code;
    record_to_update.properties.status_code_id = status_record.id;
    record_to_update.properties.status_name = status_record.name;

    // Sending the server request to modify the status
    const update_payload = [
      {
        id: observation_id, 
        status_code: status_record.code,
      }
    ];

    this.props.saveRecord(update_payload).then(data => {
      const updated_records = this.state.filteredObservations.map(record => {
        return record.id === data.id ? data : record;
      })

      // Updating the filtered observations to have the new status value
      this.setState({
        filteredObservations: updated_records,
        selectedObservation: record_to_update,
        current_status: status_code
      })
    });
  };

  handleFilterChange = event => {
    const filter = event.target.value;
    const words = filter.split(" ");

    const { observations } = this.props;
    let filteredObservations = [];
    // filter based on the words
    for (let word of words) {
      filteredObservations = observations.filter(record => {
        const { id, properties } = record;

        if ((id + "").indexOf(word) >= 0) return true;
        for (let item in properties) {
          // force everything as text and check
          if (
            (properties[item] + "").toLowerCase().indexOf(word.toLowerCase()) >=
            0
          )
            return true;
        }
        return false;
      });
    }

    this.setState({
      filteredObservations: filteredObservations,
      filter: filter
    });
  };

  modalOkHandler = event => {
    this.props.onStartTileDownload();
    logger.info("User has started the batch download process.");
  };

  modalCancelHandler = event => {
    this.props.onCancelDownload();
  };

  closeDetails = () => {
    this.setState({
      detailsVisible: false
    });
  };

  toggleLayerControlHandler = () => {
    this.setState({
      layerControlDialogVisible: !this.state.layerControlDialogVisible
    });
  };

  locateMeHandler = () => {
    if (this.props.map) {
      // Check if actively watching location
      if (this.state.watching) {
        logger.info("Locate Me turned off.");
        this.props.map.stopLocate();
        this.setState({ watching: false });
      } else {
        logger.info("Locate Me turned on.");
        this.props.map.on("locationfound", () => {
          this.setState({
            locationErrorMessage: "",
            locationFound: true
          });
          setTimeout(() => {
            this.setState({
              locationFound: false
            });
          }, 200);
        });
        this.props.map.on("locationerror", e => {
          this.setState({
            locationError: true,
            locationErrorMessage: e.message
          });
          setTimeout(() => {
            this.setState({
              locationError: false
            });
          }, 200);
        });
        this.props.map.locate({
          setView: true,
          watch: this.props.gps.watch,
          enableHighAccuracy: this.props.gps.highAccuracy
        });
        this.setState({ watching: this.props.gps.watch });
      }
    }
  };

  render() {
    const {
      classes,
      loading,
      observations,
      mapZoomCenter,
      onZoomEnd,
      status,
      storeMainMap,
      tilesDownloadReady,
      tilesDownloadPackage
    } = this.props;
    const {
      filteredObservations,
      currentView,
      selectedObservation,
      filter,
      detailsVisible,
      feedback_visible
    } = this.state;

    const alertComponent = (
      <Alerts
        observations={filteredObservations}
        selectedObservation={selectedObservation}
        selectedHandler={this.observationSelectionHandler}
        statusLookup={status}
        handleChangeStatus={this.handleChangeStatus}
      />
    );
    const mapComponent = (
      <Map
        id={"main"}
        storeMainMap={storeMainMap}
        showLayerControl={this.state.layerControlDialogVisible}
        toggleLayerControl={this.toggleLayerControlHandler}
        alerts={filteredObservations}
        feedback={this.props.feedback}
        mapZoomCenter={mapZoomCenter}
        onZoomEnd={onZoomEnd}
        selectedAlertId={selectedObservation.id}
        alertSelectedHandler={this.observationSelectionHandler}
        feedbackSelectionHandler={this.feedbackSelectionHandler}
        mapInitialized={this.state.initialized}
        currentView={currentView}
      />
    );
    const tilesDownloadDialog = (
      <Modal show={tilesDownloadReady} modalClosed={this.modalCancelHandler}>
        <BatchDownload
          continue={this.modalOkHandler}
          cancel={this.modalCancelHandler}
          downloadPackage={tilesDownloadPackage}
        />
      </Modal>
    );

    const should_show_filter_search = !detailsVisible && !feedback_visible && currentView !== 'FEEDBACK_FORM';

    console.log('Alert Contrainer currentView', currentView);
    return (
      <CurrentStatusContext.Provider value={this.state.current_status}>
      <div className={classes.container}>
        {tilesDownloadDialog}
        {should_show_filter_search && (
          <Grid container alignItems="flex-end" className={classes.filter}>
            <Grid item xs={6}>
              <TextField
                id="filter"
                label="Filter"
                value={this.state.filter}
                onChange={this.handleFilterChange}
                margin="dense"
                className={classes.TextField}
                fullWidth
              />
            </Grid>
            <Grid item xs={6} className={classes.RecordCount}>
              Showing {this.state.filteredObservations.length} records
            </Grid>
          </Grid>
        )}
        <Grid container className={classes.root}>
          {currentView === MAIN_VIEW_LIST && (
            <Grid item xs={12}>
              {alertComponent}
            </Grid>
          )}
          {currentView === MAIN_VIEW_MAP && (
            <Grid item xs={12}>
              {mapComponent}
            </Grid>
          )}

          {currentView === FEEDBACK_FORM && (
            <Grid item xs={12}>
              <FeedbackForm actionViewHandler={this.actionViewHandler}/>
            </Grid>
          )}
        </Grid>
        {detailsVisible ? (
          <ObsDetail
            observation={selectedObservation}
            bookmarks={this.state.filteredBookmarks}
            closeDetails={this.closeDetails}
            statusChangeHandler={this.handleChangeStatus}
          />
        ) : feedback_visible ? (
          <FeedbackContainer feedback={this.state.selected_feedback} closeFeedbackContainer={this.closeFeedbackContainer}/> 
        ) : (
          <ActionbarItems
            setView={this.actionViewHandler}
            layerControl={this.toggleLayerControlHandler}
            currentView={currentView}
            locateMe={this.locateMeHandler}
            watching={this.state.watching}
            locationErrorMessage={this.state.locationErrorMessage}
            locationFound={this.state.locationFound}
            locationError={this.state.locationError}
          />
        )}
      </div>
      </CurrentStatusContext.Provider>
    );
  }
}

const mapStateToProps = state => {
  let {
    lookups: {
      products,
      status,
      systemdefaults
    },
    projects: {
      projects
    },
    map: {
      mapZoomCenter,
      currentView,
      tilesDownloadReady,
      tilesDownloadPackage
    },
    alerts: {
      loading,
      error
    }
  } = state;

  let bookmarks;
  const _sd = systemdefaults.find(item => item.parameter === "BOOKMARKS");
  if (_sd) {
    try {
      bookmarks = _sd.hasOwnProperty("data")
        ? _sd.data
        : JSON.parse(_sd["default_value"]);
    } catch (err) {
      logger.error("Error parsing system bookmarks", { err, _sd });
    }
  }

  const observations = getFilteredAlerts();

  return {
    isAuthenticated: state.auth.token !== null,
    observations,
    feedback: state.alerts.feedback,
    loading: loading,
    error: error,
    mapZoomCenter,
    currentView,
    status,
    products,
    projects,
    bookmarks,
    tilesDownloadPackage,
    tilesDownloadReady,
    gps: state.app.gps,
    map: state.map.mapMain
  };
};

const mapDisptachToProps = dispatch => {
  return {
    onZoomEnd: zoomcenter => dispatch(Actions.updateMapZoomCenter(zoomcenter)),
    onFetchObservations: () => {
      dispatch(Actions.fetchAlertsFromCache());
    },
    onFetchFeedback: () => {
      dispatch(Actions.fetchFeedbackFromCache());
    },
    onFetchSupportData: () => {
      dispatch(Actions.refreshProjects());
      dispatch(Actions.refreshLookups());
      dispatch(Actions.fetchBaseLayers());
      dispatch(Actions.fetchReferenceLayers());
    },
    onCancelDownload: () => dispatch(Actions.cancelTileDownload()),
    onStartTileDownload: () => dispatch(Actions.startTileDownload()),
    storeMainMap: map => dispatch(Actions.storeMainMap(map)),
    saveRecord: record => dispatch(Actions.saveAlert(record)),
    onCurrentView: currentView =>
      dispatch(Actions.updateCurrentView(currentView)),
    onSelectObservation: observation => dispatch(Actions.selectAlert(observation))
  };
};

export default connect(
  mapStateToProps,
  mapDisptachToProps
)(withStyles(styles)(alertContainer));
