import React, { useState } from 'react';
import IconButton from "@material-ui/core/IconButton";
import FeedbackPhoto from "./FeedbackPhoto"
import { Delete, Comment } from "@material-ui/icons";
import ImagePreview from '../../../components/ImagePreview/ImagePreview';
import { makeStyles } from '@material-ui/core';
import { connect } from 'react-redux';

const large_screen_breakpoint = '@media (min-width: 35.5rem)'
const useStyles = makeStyles(theme => ({
  root: {
    padding: 0,
    height: 'calc(100% - 7.5rem)',
  },
  MediaPane: {
    height: 'calc(100% - 3rem)',
    padding: '5px',
    [large_screen_breakpoint]: {
      height: 'calc(100% - 4rem)',
      flexDirection: 'row',
      flexWrap: 'nowrap',
      display: 'flex',
    },
  },
  ImageContainer: {
    position: 'relative',
    width: '100%',
    height: '80%',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(43, 57, 144, 0.25)',
    [large_screen_breakpoint]: {
      width: '85%',
      height: '100%',
    }
  },
  CommentIcon: {
    position: 'absolute',
    bottom: '1%',
    left: '1%',
  },
  Comment: {
    position: 'absolute',
    bottom: '2%',
    left: '10%',
    right: '10%',
    maxHeight: '30%',
    overflowY: 'scroll',
    backgroundColor: 'background-color: rgba(245, 245, 220, 0.75)',
  },
  TrashIcon: {
    position: 'absolute',
    bottom: '1%',
    right: '1%',
  },
  Image: {
    width: 'auto',
    height: 'auto',
    maxWidth: '100%',
    maxHeight: '100%',
  },
  ListContainer: {
    height: '20%',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    alignContent: 'flex-start',
    justifyContent: 'flex-start',
    overflowY: 'scroll',
    [large_screen_breakpoint]: {
      width: '15%', 
      height: '100%',
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      overflowY: 'scroll',
    }
  },
  Image: {
    width: 'auto',
    height: 'auto',
    maxWidth: '100%',
    maxHeight: '100%',
  }
}))

function FeedbackPhotos(props) {
  const classes = useStyles();
  const {feedback: media, feedback_media, feedback} = props;
  const [commentVisible, setCommentVisible] = useState(false);
  const addedFeedbackMedia = feedback_media.filter(media => media.fbguid == feedback.properties.guid)
  const allMedia = [...media.media, ...addedFeedbackMedia
                    .filter(m => !media.media
                    .some(existingMedia => existingMedia.id == m.id))]
                    .sort((a, b) => new Date(b.created) - new Date(a.created))
  const [selectedMedia, setSelectedMedia] = useState(allMedia.length > 0 ? allMedia[0] : null);                    
  const [comment_text, setCommentText] = useState(
    selectedMedia ? cleanComment(selectedMedia) : ''
  );
  function cleanComment(source) {
    const comment = source.comment_text
    const position_of_text_to_remove = comment ? comment.indexOf(' (Photo)') : -1
    const cleaned_comment = position_of_text_to_remove === -1 
      ? comment 
      : comment.slice(0, position_of_text_to_remove);

    return cleaned_comment;
  }

  const changePhotoHandler = (id) => {
    const source = allMedia.find(item => item.id === id);
    if (!source) return;

    setSelectedMedia(source);
    setCommentText(cleanComment(source));
  }

  const toggleCommentHandler = () => {
    setCommentVisible(visible => !visible);
  }

  const should_display_image_preview = selectedMedia !== null;
  const should_display_comment = selectedMedia && commentVisible;
  const should_display_comment_button = selectedMedia !== null;

  return (
    <div className={classes.root}>
      <div className={classes.MediaPane}>
        <div className={classes.ImageContainer}>
          {/* Image */}
          {should_display_image_preview && (
            <ImagePreview clasName={classes.Image} imgURL={selectedMedia.download_url} />
          )}

          {/* Comment */}
          {should_display_comment && (
            <div className={classes.Comment}>
              <input type='text' value={comment_text} readOnly style={{display: 'table-cell', width: '100%'}} />
            </div>
          )}

          {/* ActionIcons */}
          {should_display_comment_button && (
            <div className={classes.CommentIcon}>
              <IconButton
                color={selectedMedia ? 'inherit' : 'primary'}
                className={classes.root}
                onClick={toggleCommentHandler}
              >
                <Comment />
              </IconButton>
            </div>
          )}
        </div>

        <div className={classes.ListContainer}>
            {allMedia.map(item => (
              <FeedbackPhoto key={item.id} media={item} clicked={changePhotoHandler} />
            ))}
        </div>  
      </div>
    </div>
  )
}

const mapStateToProps = (state) => ({
  feedback_media: state.comment_media.feedback_media,
});

export default connect(mapStateToProps)(FeedbackPhotos);