export const DATA_REFRESH_DATE = "DATA_FRESH_DATE";
export const DATE_RANGE_SELECTION = "DATE_RANGE_SELECTION";
export const LATEST_OBSERVATION_DATE = "LATEST_OBSERVATION_DATE";
export const DOWNLOAD_THRESHOLD_SETTINGS = "DOWNLOAD_THRESHOLD_SETTINGS";

export const ACCOUNT = "ACCOUNT";
export const EXPIRATION_DATE = "EXPIRATION_DATE";
export const TOKEN = "TOKEN";
export const USER_ID = "USER_ID";

export const TILE_DOWNLOAD_SETTINGS = "TILE_DOWNLOAD_SETTINGS";
export const GPS_SETTINGS = "GPS_SETTINGS";

export const MAP_ZOOM_CENTER = "MAP_ZOOM_CENTER";

export const DARK_MODE = "DARK_MODE";
export const OFFLINE_MODE = "OFFLINE_MODE";

export const APP_INITIALIZED = "APP_INITIALIZED";
export const BUCKET = "BUCKET";

export const IS_USING_FUSION_AUTH = "IS_USING_FUSION_AUTH";
export const SHOW_EXAMPLE_PROJECTS = 'SHOW_EXAMPLE_PROJECTS';
